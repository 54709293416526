// <editor-fold "App specific variables">
@import "vars";

:root {

  // app colors
  --app-color--blue-lightest: #9fb9cf;
  --app-color--blue-lighter: #73899e;
  --app-color--blue-light: #485b6b;

}

// </editor-fold>

// <editor-fold "Base/reset">

html, body, #root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

body {
  color: var(--color-text--inverse);
  background-color: var(--color-bg--primary-dark);
  font-weight: 300;

  h1 {
    font-size: 1.625rem;
  }

  h2 {
    font-size: var(--size-font--md);
  }

  h3 {
    font-size: var(--size-font--nm);
  }
}

#root {
  position: relative;
  z-index: 0;
}

svg {
  shape-rendering: geometricPrecision;
  stroke: transparent;
  fill: transparent;
}

figure {
  margin: 0;
  padding: 0;
  display: block;
}

input {
  padding: 0.6rem 0;
}

::-webkit-scrollbar-track {
  border: 1px solid var(--color-bg--primary);
  background-color: var(--color-bg--primary);
}

::-webkit-scrollbar {
  width: 10px;
  background-color: var(--color-bg--primary);
}

::-webkit-scrollbar-thumb {
  background-color: #53687B;
  //border-radius: 0.25em;
  //border-bottom-left-radius: 0.25em;
  //border-top-right-radius: 0.25em;
}

// </editor-fold>

// <editor-fold "App mixins">

@mixin content-box() {
  padding: var(--size-geo--sm);

  @include from-screen("mobile-l") {
    padding: var(--size-geo--nm);
  }

  @include from-screen("laptop") {
    padding: var(--size-geo--md);
  }
}

@mixin content-hbox() {
  padding-left: var(--size-geo--sm);
  padding-right: var(--size-geo--sm);

  @include from-screen("mobile-l") {
    padding-left: var(--size-geo--nm);
    padding-right: var(--size-geo--nm);
  }

  @include from-screen("laptop") {
    padding-left: var(--size-geo--md);
    padding-right: var(--size-geo--md);
  }
}

@mixin segment-box() {
  margin: var(--size-geo--sm);

  @include from-screen("mobile-l") {
    margin: var(--size-geo--nm);;
  }

  @include from-screen("laptop") {
    margin: var(--size-geo--md);;
  }
}

@mixin default-pane-shadow() {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

@mixin default-text-shadow() {
  text-shadow: 1px 1px 10px rgba(10, 31, 48, 0.5);
}

@mixin modal-single-col() {
  min-width: 20rem;
  max-width: 85vw;
  width: 32.5vw;
  min-height: 50vh;
  max-height: 90vh;
  position: relative;

  @include from-screen("tablet") {
    min-width: 22.5rem;
    min-height: 62.5vh;
  }

  @include from-screen("desktop") {
    min-width: 25rem;
    min-height: 75vh;
  }
}

@mixin light-border-top-gradient($z-index: 3) {
  &:after {
    position: absolute;
    display: block;
    z-index: $z-index;
    content: ' ';
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.0125) 0%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.0125) 100%);
  }
}

@mixin light-border-bottom-gradient($z-index: 3) {
  &:after {
    position: absolute;
    display: block;
    z-index: $z-index;
    content: ' ';
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.0125) 0%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.0125) 100%);
  }
}

// </editor-fold>

// <editor-fold "UI/Common overrides">

// General/misc stuff

.txt-mono {
  font-family: var(--font-monospace);
}

.ui-button.ui-button--icon-only > .ui-icon {
  height: 1.24125em;
}

.ui-button {
  font-size: 0.825rem;
  text-transform: uppercase;
  padding: 0;
  transition: all 0.25s ease-in-out;
  align-items: stretch;
  font-weight: 400;

  &.txt-b {
    font-weight: bold;
  }

  &:not(.ui-button--semantic) {

    &:not(.txt-xs):not(.txt-sm):not(.txt-md):not(.txt-lg):not(.txt-xl) {
      border-radius: 0.25rem;
      padding: 1.125em 1.5em;
    }

    &.txt-xs,
    &.txt-sm {
      border-radius: 0.25rem;
      padding: 0.75em 1.25em;
    }

    &.txt-sm {
      font-size: 0.75rem;
    }
  }

  &.icon-scale {

    > .ui-icon {
      transform-origin: center center;
      transform: scale(1.325);
    }
  }

  &-group {
    display: flex;

    &--stretch {
      &.ui-button-group--h > .ui-button,
      &.ui-button-group--hr > .ui-button {
        flex: 1;
      }
    }
  }

  &--pill.ui-button--icon-only {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-text--inverse);
    text-transform: lowercase;
    width: 1.25rem;
    height: 1.25rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 0 !important;
    border-radius: 50% !important;
  }

  &--outlines.ui-button--clr-primary {
    color: var(--color-text--inverse-dim);
    border-color: var(--color-text--inverse-dim);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      color: var(--color-text--inverse-bright);
    }
  }

  &--outlines.ui-button--clr-default {
    border-color: var(--color-text--inverse-dimmer) !important;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--color-bg--primary-hover);
    }

    &:not([disabled]):focus {
      border-color: var(--color-text--inverse) !important;
    }

    &:disabled {
      border-color: var(--color-text--inverse-dimmest) !important;
    }
  }

}

.ui-button-group--h,
.ui-button-group--hr {

  > .ui-button {

    &:not(:first-child) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &:not(:last-child) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

.ui-text {

  &--breathe {
    line-height: 1.625 !important;
  }
}

.ui-text-block,
.ui-text-title {
  line-height: 1.425;
}

.ui-text-title {
  font-family: var(--font-title) !important;
  font-weight: 400;

  > .ui-text-line {
    white-space: normal;
    word-break: normal;
  }

  &.txt-b > .ui-text-line {
    font-weight: bold;
  }
}

.ui-dropdown {

  &__content {
    margin-top: var(--size-geo--xs);
  }

  &__content,
  &__content > .ui-action-menu {
    border-radius: 0.25rem;
    background-color: var(--color-bg--primary);
    color: var(--color-text--inverse);
  }
}

.ui-action-menu {
  margin-top: 0;
}

.ui-panel {
  background-color: #1A2A39;
  color: var(--color-text--inverse);
}

.ui-page {

  &-container {

    #root > &:only-child {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .app-layout__viewport > &:only-child {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;

      > *:last-child {
        flex: 1;
      }
    }
  }

  &-segment {
    @include segment-box;

    .ui-page-header + &,
    .app-page-header + &,
    & + & {
      margin-top: 0;
    }

  }

  &-content {
    @include content-box;
  }
}

.ui-pager {

  .ui-button {

    &:not(:last-of-type) {
      margin-right: var(--size-geo--sm);
    }
  }
}

.ui-toast.toast {
  -webkit-font-smoothing: antialiased;
  font-family: inherit;
  font-size: 0.9rem;
  color: var(--color-text--inverse) !important;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.5em 1.75em;
  white-space: pre-line;
  min-height: 0;
  margin-bottom: 1em;
  border-radius: 0.325em;
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  max-width: 16rem;

  @include from-screen("tablet") {
    max-width: 20rem;
  }

  @include from-screen("desktop") {
    max-width: 24rem;
  }

  &.ui-toast {

    &--success {
      background-color: var(--color-bg--success) !important;
    }

    &--info {
      background-color: var(--color-bg--primary) !important;
      border: 1px solid rgba(255, 255, 255, 0.25) !important;
    }
  }
}

.ui-badge {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0.125em 1em;
  border-radius: 3em;

  .txt-sm &,
  &.txt-sm {
    //text-transform: uppercase;
  }

  &--clr-default {
    background: rgba(255, 255, 255, 0.05);
  }

  &--clr-success {
    background: var(--color-bg--success);
  }

  &--clr-warn {
    background: var(--color-bg--warn-dark);
  }

  > .ui-icon {
    margin-right: 0.3125em;
  }
}

@mixin form-control-optics--has-focus {
  box-shadow: none;
  border-bottom-color: var(--app-color--blue-lightest);
  color: var(--color-text--inverse-bright);
}


@mixin form-control-optics {
  &,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid var(--app-color--blue-light);
    color: var(--color-text--inverse);
    transition: background-color 0.2s ease, color 0.2s ease, border 0.2s ease, opacity 0.2s ease;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition-delay: 99999s;
    @include form-control-optics--has-focus;
  }
}

@mixin form-label-optics {
  font-size: var(--size-font--sm);
  color: var(--app-color--blue-lightest);
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.03125em;
  line-height: 1.42;
}

.ui-data {

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;

    @include form-control-optics;

    &__label,
    &__data {
    }

    &__label {
      @include form-label-optics;
    }

    &__data {
      padding: 0.5em 0;
    }

    &:not(:first-of-type):not(:only-child) {
      margin-top: var(--size-geo--nm);
    }
  }

  &-itemset {
    display: flex;
    flex-direction: column;
  }
}

.ReactModal__Content {
  display: flex !important;
  flex-direction: column;
  transition: opacity 150ms linear, transform 150ms linear;

  @include up-to-screen('tablet') {
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  &, &--before-close {
    opacity: 0;
    transform: scale(0.925);
  }

  &--after-open {

    &.ReactModal__Content {
      opacity: 1;
      transform: scale(1);
    }
  }

  &:not(.ui-modal) {
    @include modal-single-col;
  }

  &.ui-modal {
    color: var(--color-text--inverse);
  }
}

.ui-modal__overlay__confirmation,
.ui-modal__overlay__route,
.ui-modal__overlay__dialog {
  > .ReactModal__Content {
    transition: opacity var(--modal-transition-timeout), transform var(--modal-transition-timeout) ease, box-shadow 2s ease-in-out;
    transform: scale(1) translateY(5vw);
    box-shadow: 0 0 3.25em rgba(0, 0, 0, 0.25);
    background-color: var(--color-bg--primary-dark);

    .react-calendar {
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }

  > .ReactModal__Content--after-open {
    //box-shadow: 0 0 5em rgba(0, 0, 0, 0.5);
    transform: scale(1) translateY(0);
  }

  > .ReactModal__Content--before-close {
    transform: scale(1) translateY(5vw);
    //box-shadow: 2em 0 0 rgba(0, 0, 0, 0.25);
  }
}


.ui-table {
  overflow: auto;
  position: relative;
  z-index: 0;

  &__cell, &__cell.body {
    background: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.05) !important;
    position: relative;
    z-index: 10;
  }

  &__cell.header {
    background: rgba(255, 255, 255, 0.05);
    border-bottom: 0;
    position: relative;
    z-index: 20;
  }

  &__cell.body,
  &__cell.header {

    &:not(:first-of-type) {
      border-left: 0;
    }
  }
}

.ui-table + .ui-toolbar {
  border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  > .ui-toolbar__slot > .ui-button-group {

    > .ui-button.ui-button--default.ui-button--clr-default {
      color: #fafafa;
      background: transparent;

      &:hover, &:focus {
        background: rgba(255, 255, 255, 0.05);
        color: #fff;
      }
    }
  }
}

.ui-confirmation-modal {
  background-color: var(--color-bg--primary-dark);
  color: var(--color-text--inverse);
  border-radius: 0.25em;

  &__header {
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
    background-color: var(--color-bg--primary);
    margin-bottom: var(--size-geo--nm);
  }

  &__footer {

    .ui-button {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border: 0 !important;

      &:not(:first-child) {
        border-bottom-left-radius: 0 !important;
      }

      &:not(:last-child) {
        border-bottom-right-radius: 0 !important;
      }

      &--clr-default {
        background-color: var(--color-bg--primary);
        color: var(--color-text--inverse);

        &:not([diabled]):hover {
          background-color: var(--color-bg--primary-hover);
          color: var(--color-text--inverse-bright);
        }
      }

      &--clr-primary {
        background-color: var(--color-bg--accent);
        color: var(--color-text--inverse);

        &:not([diabled]):hover {
          background-color: var(--color-bg--accent-hover);
          color: var(--color-text--inverse-bright);
        }
      }
    }
  }
}

.ui-modal-dialog {

  &__confirmation {
    background-color: var(--color-bg--primary-dark);
    color: var(--color-text--inverse);
    border-radius: 0.325rem;
  }

  &__header {
    background: #223544;
    border-bottom: 1px solid #162A3A;
    border-top-left-radius: 0.325rem;
    border-top-right-radius: 0.325rem;

    &__title {
      flex: 1;
      font-size: 1.5rem;
      padding: var(--size-geo--sm) 1.25rem;
    }

    &__close {
      font-size: 1.5rem;
      opacity: 0.625;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 var(--size-geo--sm);
      border-left: 1px solid #162A3A;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 10;
    }
  }

  &__search {
    background: #2D3F4E;
    display: flex;
    flex-direction: row;
    padding: 0.625rem;

    > input {
      flex: 1;
      max-width: 100%;
      background: #FFFFFF;
      color: #94ADC1;
      font-size: 0.875rem;
      padding-left: 0.5rem;

      ::-webkit-input-placeholder,
      :-ms-input-placeholder,
      ::placeholder {
        color: #94ADC1;
      }

      &:focus {
        color: #0A1F30;
      }
    }
  }
}

.ui-tooltip-menu {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  &__toggle {
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
  }

  &__container {
    $__size: 0.625rem;
    font-size: 1rem;
    color: #fff;
    padding: 0;
    border: 0;
    border-radius: 0.325rem;
    filter: drop-shadow(0.5rem 0px 2rem rgba(0, 0, 0, 0.325));

    &.show {
      opacity: 1 !important;
    }


    &:after {
      top: 50% !important;
      margin-top: (-1 * $__size) !important;
      border-top: $__size solid transparent !important;
      border-bottom: $__size solid transparent !important;
    }

    &.place-left:after {
      border-left-color: #223545 !important;
      border-left-style: solid !important;
      border-left-width: $__size !important;
      right: (-1 * $__size) !important;
    }

    &.place-right:after {
      border-right-color: #223545 !important;
      border-right-style: solid !important;
      border-right-width: $__size !important;
      left: (-1 * $__size) !important;
    }
  }

  &__item {
    margin: 0;
    padding: var(--size-geo--sm);
    min-width: 16rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid #384958;
    }

    &--link {
    }

    &--button {
      border-radius: 0;
      text-transform: none;
      flex-direction: row-reverse;
      font-size: 1rem;

      > .ui-text {
        flex: 1;
        text-align: left;
        color: #fff;
      }

      > .ui-icon {
        margin-right: 0 !important;
        border-radius: 50%;
        background-color: #374957;

        > svg {
          transform-origin: center;
          transform: scale(0.9);
        }
      }
    }

    &:first-child {
      border-top-left-radius: 0.325rem;
      border-top-right-radius: 0.325rem;
    }

    &:last-child {
      border-bottom-left-radius: 0.325rem;
      border-bottom-right-radius: 0.325rem;
    }

    &:hover {
      background-color: #2D3F4E;
    }
  }
}


.ui-duration-slider {
  display: grid;
  grid-gap: var(--size-geo--sm);
  grid-template-columns: 3.75rem auto 3.75rem;
  grid-template-rows: 7.5rem;
  align-items: center;
  justify-items: center;

  > .ui-number-slider .ui-number-range-input {
    width: 3.75rem;
  }
}

.ui-number-slider {
  display: grid;
  grid-gap: 0;
  grid-template-columns: min-content;
  grid-template-rows: 1.875rem 3.75rem 1.875rem;
  align-items: center;
  justify-items: center;

  .ui-number-range-input {
    width: 7.5rem;
    font-size: 1.25rem;
    background: transparent;
  }

  &__stepper {
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 0.3125rem;
    justify-self: stretch;
    justify-content: center;
    align-items: center;
    height: 1.875rem;
    font-size: var(--size-font--sm);
    cursor: pointer;
    appearance: none;
    background: transparent;
    display: flex;
    flex-direction: row;

    &--next {
      align-self: start;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-width: 0;
    }

    &--next,
    &--prev {
    }

    &--prev {
      align-self: end;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top-width: 0;
    }

    &--disabled {
      cursor: default;
    }

    &:not(.ui-number-slider__stepper--disabled):hover {
      background: rgba(255, 255, 255, 0.0375);
    }
  }

  &__content {
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: column;
  }

  &__unit-text {
    text-align: center;
    color: var(--color-text--inverse-dim);
    font-size: var(--size-font--xs);
    padding: 0.2rem 0;
    //margin-top: var(--size-geo--xs);
  }
}

.ui-number-range-input {
  @include form-control-optics;

  display: block;
  width: 3.75rem;
  background: transparent;
  border: 0;
  text-align: center;
  color: var(--color-text--inverse-bright);

  &:focus {
    @include form-control-optics--has-focus;
  }
}

.ui-splash-message {

  &__title,
  &__item {
    text-align: center;
    max-width: clamp(15rem, 50%, 30rem);
  }

  &__title {
    margin-bottom: var(--size-geo--md);
    font-size: 1.875rem;
  }

  &__item {
    font-size: 1.3125rem;
  }

  &__item + &__item {
    margin-top: var(--size-geo--nm);
  }

  &__actions {
    margin-top: var(--size-geo--md);

    .ui-button:only-child {
      min-width: 20rem;
    }

    .ui-button:not(:only-child) {
      min-width: 10rem;
    }
  }
}


// Forms stuff
input:not([disabled]) {
  user-select: auto !important;
}

input.tiny {
  box-sizing: border-box;
  border: 1px solid #2D3F4E;
  border-radius: 0.3125rem;
  background-color: transparent;
  width: 3.625rem;
  text-align: center;
  padding: var(--size-geo--xs) var(--size-geo--sm);
  font-size: var(--size-font--sm);

  &:focus {
    color: var(--color-text--inverse-bright);
    border-color: #40586d;
  }
}

.form {

  &-field {
    line-height: 1;

    &__control {
      @include form-control-optics;

      .form-field--has-focus &,
      &:focus {
        @include form-control-optics--has-focus;
      }

      &--is-readonly {
        &, &:hover, &:focus {
          background-color: transparent;
          border-bottom: 1px solid var(--app-color--blue-light);
          color: var(--color-text--inverse);
        }
      }

      &--textarea {
        border: 0;
        padding: 1.125em 1.5em;

        @include form-control-optics;

        &:focus {
          @include form-control-optics--has-focus;
        }
      }

      &--text,
      &--password,
      &--number {
        padding-left: 0;
        padding-right: 0;
      }

      &--star-rating {
        cursor: pointer;
        padding: var(--size-geo--sm) 0 var(--size-geo--sm) 0;

        .ui-icon {
          cursor: pointer;

          &.active {
            color: var(--color-text--accent);
          }

          &.inactive {
            opacity: 0.75;
          }

          &:hover {
            color: var(--color-text--accent);
          }
        }

        > .form-field__control__subcontrol:not(:first-child) {
          margin-left: 0.25rem;
        }
      }
    }

    &__label {
      position: relative;
      transition: transform 0.2s ease;
      transform: translate3d(0, 0, 0);
      @include form-label-optics;

      &:not(:only-child) {
        .form-field--v & {
          padding-bottom: 0.1em;
        }

        .form-field--v:not(.form-field--has-focus):not(.form-field--has-value) & {
          transform: translate3d(0, 2em, 0);
          color: var(--app-color--blue-lighter);
          //font-weight: 300;
        }

      }
    }

    &--v {
      &.hide-placeholder.form-field--has-focus:not(.form-field--has-value) {

        > .form-field__label {
          transform: translate3d(0, 2em, 0) !important;
        }
      }
    }

    &--type {

      &-date {

        .ui-dropdown {

          &__preview {

            > .ui-button {
              padding-left: 0 !important;
            }
          }
        }
      }

      &-date-inputs {
        .ui-box.form-field__control {
          min-width: 2rem;
          width: 100%;

          .form-field__select__value-container {
          }
        }
      }

      &-locale-switcher,
      &-content-select,
      &-date-inputs,
      &-select {

        &.form-field--has-focus:not(.form-field--has-value) > .form-field__label:not(:only-child) {
          transform: translate3d(0, 0, 0) !important;
        }

        .ui-action-menu {
          margin-top: 0;
          background-color: var(--color-bg--primary);
          color: var(--color-text--inverse);
          max-height: 50vh;
          min-height: 0;
          overflow-x: hidden;
          overflow-y: auto;

          &__preview {
            background-color: transparent;
            color: var(--color-text--inverse);
            border-bottom: 1px solid var(--app-color--blue-light);
            width: 100%;

            > .ui-button {
              border: 0;
              border-radius: 0;
              margin: 0 !important;
              padding: 0.625em 0.625em 0.625em 0 !important;
              background: transparent;
              color: var(--color-text--inverse);
              text-transform: none;

              &:hover {
                background: transparent;
                border: 0;
                color: var(--color-text--inverse-bright);
              }
            }

            &__primary {
              flex: 1;
              text-align: left;
              justify-content: flex-start;
            }
          }
        }

        > .ui-dropdown {
          width: 100%;

          &__content {
            background-color: var(--color-bg--primary);
            color: var(--color-text--inverse);
          }
        }

        .form-field__content-select__control,
        .form-field__locale-switcher__control,
        .form-field__select__control {
          box-shadow: none !important;
        }
      }

      &-textarea {

        .form-field__control {
          padding-left: 0;
        }
      }

      &-date-picker {

        &__display {
          border-bottom: 1px solid var(--app-color--blue-light);

          &.ui-text,
          &.ui-button {
            padding: 0.625em;
          }

          &.ui-button > .ui-icon:not(:only-child) {
            margin-right: 0;
          }
        }
      }

      &-star-rating {

        &.form-field--v.form-field--has-focus,
        &.form-field--v.form-field--is-active,
        &.form-field--v.form-field--is-active.form-field--has-focus {
          > .form-field__label:not(:only-child) {
            transform: none !important;
          }
        }
      }

      &-number-slider {

        &.form-field.form-field--v {
          align-items: center;
        }

        &.form-field--v.form-field--has-focus,
        &.form-field--v.form-field--is-active,
        &.form-field--v.form-field--is-active.form-field--has-focus {
          > .form-field__label:not(:only-child) {
            transform: none !important;
            font-weight: bold;
            text-align: center;
            margin-bottom: 1.25rem;
            color: var(--color-text--inverse);
          }
        }

        .form-field__label {
          transform: none !important;
          font-weight: bold;
          text-align: center;
          margin-bottom: 1.25rem;

          &, .form-field--has-focus & {
            color: var(--color-text--inverse) !important;
          }
        }
      }

      &-duration-slider {

        .form-field__label {
          transform: none !important;
          font-weight: bold;
          text-align: center;
          margin-bottom: 1.25rem;

          &, .form-field--has-focus & {
            color: var(--color-text--inverse) !important;
          }
        }
      }

      &-account-picture {
        transition: opacity 0.2s ease, background-color 0.2s ease, color 0.2s ease;

        &.form-field--v,
        &.form-field--vr {
          flex-direction: column-reverse;
          align-items: center;

          .form-field__label {
            transform: none !important;
          }
        }

        .form-field__label {
          opacity: 0.5;
          margin-top: var(--size-geo--sm);
          text-align: center;
        }

        &.form-field--has-focus {
          .form-field__label {
            opacity: 1;
          }
        }

        .app-user-account-picture {
          width: 100%;
          height: 100%;
          transition: opacity 0.2s ease, background-color 0.2s ease;
          border: 2px solid var(--app-color--blue-light);
        }

        &.form-field--has-focus .app-user-account-picture,
        &:hover .app-user-account-picture {
          border-color: var(--app-color--blue-lighter);
        }

        .app-user-account-picture,
        .ui-icon {
          cursor: pointer;
        }
      }

      &-fight-style-select {
        width: 100%;
        flex-direction: column-reverse;
        align-items: stretch;

        > div {
          flex: 1;
        }

        .form-field__messages:last-child {
          border-bottom: 1px dashed var(--color-bg--accent);
          background-color: hsla(348, 71%, 46%, 0.1);

          > .form-field__message {
            padding: var(--size-geo--sm) var(--size-geo--nm);
            margin-top: 0;
          }
        }
      }

      &-custom-fight-styles {

        > div {
          flex: 1;
        }

        &__items {

          &__item {
            @include form-control-optics;
            background: rgba(255, 255, 255, 0);
            transition: background-color 0.2s ease, opacity-color 0.2s ease;
            padding: 0 var(--size-geo--nm) 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom-style: dashed;

            input {
              color: var(--color-text--inverse);
              flex: 1;
              background: none;
              border-radius: 0;
              border: 0;
              outline: none;
              padding: var(--size-geo--sm) 0 var(--size-geo--sm) var(--size-geo--nm);
              width: 100%;

              &:focus {
                box-shadow: none;
                outline: none;
                color: var(--color-text--inverse-bright);
              }
            }

            ::-webkit-input-placeholder,
            ::-moz-placeholder,
            :-ms-input-placeholder,
            :-moz-placeholder {
              color: var(--color-text--default-dim);
            }

            &:first-child {
              border-top: 1px solid var(--app-color--blue-light);
            }
          }
        }
      }

      &-toggle {

        &.form-field--v.form-field--has-focus,
        &.form-field--v.form-field--is-active,
        &.form-field--v.form-field--is-active.form-field--has-focus {
          > .form-field__label:not(:only-child) {
            transform: translate3d(0, 1.5em, 0);
            pointer-events: none;
          }
        }
      }

      &-training-plan-units {
        flex-direction: column-reverse;
        align-items: stretch;

        .form-field__messages:last-child {
          border-bottom: 1px dashed var(--color-bg--accent);
          background-color: hsla(348, 71%, 46%, 0.1);

          > .form-field__message {
            padding: var(--size-geo--sm) var(--size-geo--nm);
            margin-top: 0;
          }
        }
      }
    }

    &--is-empty {

      &.hide-placeholder {

        .ui-action-menu__preview__primary {
          opacity: 0;
        }
      }
    }

    &--has-focus {

      &.form-field--type {

        &-locale-switcher,
        &-content-select,
        &-select {
          .ui-action-menu__preview {
            border-bottom: 1px solid var(--app-color--blue-lighter);
            color: var(--color-text--inverse-bright);
          }
        }

        &-date-picker {
          &__display {
            border-bottom: 1px solid var(--app-color--blue-lighter);
            color: var(--color-text--inverse-bright);
          }
        }
      }
    }

    &__click-editable {

      &__container {
        background-color: rgba(117, 19, 61, 0);
        transition: background-color 0.2s ease-in-out;

        &:not(.form-field__click-editable__container--editing), &:not(.form-field__click-editable__container--editing) * {
          cursor: pointer !important;
        }

        &:hover {
          background-color: rgba(117, 19, 61, 0.5);
        }

        &--editing {
          //background-color: rgba(255, 255, 255, 0.1);

          &:hover {
            background-color: rgba(255, 255, 255, 0);
          }
        }
      }
    }

    &__locale-switcher,
    &__content-select,
    &__select {

      &__input > input {
        box-shadow: none !important;
      }
    }
  }

  &__message,
  &-field__message {
    margin-top: var(--size-geo--sm);
    font-size: var(--size-font--sm);
    font-style: italic;

    animation-duration: 0.325s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-name: text-fade-in;

    &--hint {
      opacity: 0.8;
      color: var(--color-text--default-dim);
    }

    &--positive {
      font-weight: 400;
      color: var(--color-text--success);
    }

    &--info {
      font-weight: 400;
      color: var(--color-text--info);
    }

    &--warn {
      font-weight: 400;
      color: var(--color-text--warn);
    }

    &--error {
      font-weight: 400;
      color: var(--color-text--error-hover);
    }
  }
}

.ui-tour-tooltip {

  &--default {
    max-width: 50vw;
  }

  &--default.ui-tour-tooltip--arrow {
    grid-gap: 0;
  }

  &--dismissable .ui-tour-tooltip__content {
    cursor: pointer;
    transition: 0.125s ease-in-out background-color;

    &:hover {
      background: var(--color-rooq--help-bg--hover);
    }
  }

  &--arrow {

    &-top {
      display: flex;
      flex-direction: column-reverse;

      > .ui-tour-tooltip__arrow {
        top: 3px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    &-right {
      display: grid;
      grid-template-columns: 1fr var(--size-geo--md);
      grid-template-rows: min-content;
      grid-template-areas: "content arrow";

      > .ui-tour-tooltip__arrow {
        right: 3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & > .ui-tour-tooltip__arrow > svg .ui-tour-tooltip__arrow__path {
        transform-origin: center;
        transform: rotate(90deg);
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;

      > .ui-tour-tooltip__arrow {
        bottom: 3px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      & > .ui-tour-tooltip__arrow > svg .ui-tour-tooltip__arrow__path {
        transform-origin: center;
        transform: rotate(180deg);
      }
    }

    &-left {
      display: grid;
      grid-template-columns: var(--size-geo--md) 1fr;
      grid-template-rows: min-content;
      grid-template-areas: "arrow content";

      > .ui-tour-tooltip__arrow {
        left: 3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & > .ui-tour-tooltip__arrow > svg .ui-tour-tooltip__arrow__path {
        transform-origin: center;
        transform: rotate(-90deg);
      }
    }
  }

  &--default &__content {
    grid-area: content;
    border-radius: 0.3125rem; // 5px
    border: 2px solid var(--color-rooq--help);
    background: var(--color-rooq--help-bg);
    padding: var(--size-geo--nm);
    font-family: var(--font-title);
    color: var(--color-rooq--help);
    font-size: 1.5625rem; // 25px
    //border-radius: 0.3125rem; // 5px
    font-weight: 400;
  }

  &--default &__arrow {
    position: relative;
    grid-area: arrow;

    > svg {
      width: var(--size-geo--md);
      height: var(--size-geo--md);
      color: var(--color-rooq--help);
    }
  }
}

// Lib overwrites

.__react_component_tooltip,
.__react_component_tooltip.show {
  padding: 0 !important;
}

.__react_component_tooltip:not(.ui-tooltip-menu__container) {
  font-size: 1rem !important;
}

// </editor-fold>

// <editor-fold "App styles">


.app {

  &, &-layout {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  &-admin, &--administration {

    &__master-detail {


      &__container {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: minmax(20rem, 2fr) 5fr;
        height: 100vh;
        overflow: hidden;
      }

      &__master,
      &__detail {
        position: relative;
        display: flex;
        flex-direction: column;
      }

      &__master {
        z-index: 100;
      }

      &__detail {
        z-index: 90;
        background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.125) 100%);
      }

      &__master-menu {

        display: flex;
        flex-direction: column;

        > a,
        > span {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--color-text--default-dim);
          transition: color 0.2s ease;
          cursor: pointer;

          > .ui-icon {
            transition: color 0.2s ease, opacity 0.2s ease;
            opacity: 0.5;
          }

          &.active {
            color: var(--color-text--accent);

            > .ui-icon {
              opacity: 1;
            }
          }

          &:not(.active):hover {
            color: var(--color-text--inverse-bright);
          }
        }
      }
    }
  }

  &-coachz {

    &__settings {


      &__container {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: minmax(20rem, 2fr) 5fr;
        height: 100vh;
        overflow: hidden;
      }

      &__master,
      &__detail {
        position: relative;
      }

      &__master {
        z-index: 100;
      }

      &__detail {
        z-index: 90;
        background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.125) 100%);
      }

      &__master-menu {

        display: flex;
        flex-direction: column;

        > a,
        > span {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--color-text--default-dim);
          transition: color 0.2s ease;
          cursor: pointer;

          > .ui-icon {
            transition: color 0.2s ease, opacity 0.2s ease;
            opacity: 0.5;
          }

          &.active {
            color: var(--color-text--accent);

            > .ui-icon {
              opacity: 1;
            }
          }

          &:not(.active):hover {
            color: var(--color-text--inverse-bright);
          }
        }
      }
    }

    &__analysis {

      &__session {
        display: grid;
        grid-template-columns: auto 1fr 1fr auto auto;
        cursor: pointer;

        &.selected {
          background-color: #281F2E;
        }
      }

      &__session-container {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 0.9rem;
        padding-bottom: var(--size-geo--xl);
      }

      &__session-segment {
        border-radius: 0;
        border: 1px solid #223545;

        &.first {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &.last {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          position: relative;
        }

        &:not(.first) {
          border-left-width: 0;
        }

        &:not(.last) {
          border-right-width: 0;
        }
      }

      &__session__abo-icon {
        position: absolute;
        top: 0;
        right: 0;
        border-bottom-left-radius: 0.25rem;
        background-color: #223545;
        font-size: 1.25rem;

        > svg {
          transform: scale(0.625);
          color: #94ADC1;
        }
      }

      .app-page-section-inset {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        background: linear-gradient(to bottom, #071D2F 0%, #15293A 100%);
        box-shadow: inset 0 1rem 2rem 0 #041929;
        border-bottom: 1px solid rgba(0, 0, 0, 0.075);
      }

      &__tabs {
        display: flex;
        flex-direction: row;

        &.app-page-menu {

          .app-page-menu__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: var(--size-geo--nm) var(--size-geo--md);
            min-width: 8rem;
            border-top: 2px solid #162A3A;
            opacity: 0.5;
            position: relative;
            cursor: pointer;

            &.active {
              border-top-color: #fff;
              opacity: 1;
              background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
              box-shadow: 0 -2px 1px 1px rgba(0, 0, 0, 0.125);

              &:after {
                opacity: 1;
                transform: translateY(0);
              }
            }

            > .ui-icon {
              font-size: 2rem;
              margin-bottom: 0.5rem;
            }

            > .ui-text {
              font-size: 0.825rem;
            }

            &:after {
              position: absolute;
              bottom: 0;
              opacity: 0;
              left: 50%;
              margin-left: -0.825rem;
              display: block;
              width: 0;
              height: 0;
              content: '';
              zoom: 1;
              border-left: 0.825rem solid transparent;
              border-right: 0.825rem solid transparent;
              border-bottom: 0.825rem solid #0E1E2F;
              transition: transform 0.2s ease, opacity 0.2s ease;
              transform: translateY(0.825rem);
            }
          }
        }
      }

      &__training-day {
        display: grid;
        grid-template-columns: repeat(8, 8.25rem);
        grid-auto-rows: 8.25rem;
        border-radius: 0.42rem;
        margin-bottom: 1.5rem;

        &__item {
          background-color: #1A2A39;
          padding: 1rem;
          overflow: hidden;
          border: 2px solid transparent;
          position: relative;

          &:last-of-type {
            border-top-right-radius: 0.42rem;
            border-bottom-right-radius: 0.42rem;
          }

          &:not(:last-of-type) {
            border-right: 1px solid #0A1F30;
          }

          &__avatar {
          }

          &__name {
            font-size: var(--size-font--sm);
            color: #94ADC1;
            margin: 0.325rem 0;
          }

          &__score {
            font-size: var(--size-font--xs);
            font-weight: bold;

            > .ui-text {
              border-radius: 0.1875rem;
              background: rgba(255, 255, 255, 0.05);
              padding: 0.1875rem 0.3750rem;
            }
          }

          &:hover {
            cursor: pointer;
            background-color: #1a3045;
          }

          &.selected {
            background-color: #281F2E;
            border-bottom-color: var(--color-bg--accent);
          }

          &__selected-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            border-top-left-radius: 0.25rem;
            background-color: var(--color-bg--accent);
            font-size: 1.125rem;
          }

          &__abo-icon {
            position: absolute;
            top: 0;
            right: 0;
            border-bottom-left-radius: 0.25rem;
            background-color: #2D3F4D;
            font-size: 1.25rem;

            > svg {
              transform: scale(0.625);
              color: #94ADC1;
            }
          }
        }

        &__header {
          padding: 1rem;
          background-color: #223544;
          border-top-left-radius: 0.42rem;
          border-bottom-left-radius: 0.42rem;
          overflow: hidden;
          border-left: 2px solid transparent;
          border-right: 2px solid transparent;

          &__day {
          }

          &__date {
            font-size: 2rem;
          }

          &__month {
            font-size: 1.25rem;
            line-height: 1.825 !important;
            margin-left: 0.825rem;
          }
        }
      }
    }

    &__clubs {

      &__manager {
        @include fx--pane-fade-in;
      }

      &__cards {

        &--initializing {
          display: flex;
          flex-direction: column;
          flex: 1;

          .app-club__cta-card {
            max-height: 100% !important;
            flex: 1;
            padding-bottom: 0 !important;
            min-height: 22rem;

            .ui-text-block,
            .ui-text-title {
              text-align: center;
              max-width: clamp(15rem, 50%, 30rem);
            }

            .ui-text-title {
              font-size: 1.875rem;
            }

            .ui-text-block {
              font-size: 1.3125rem;
            }
          }
        }

        .app-club__card {
          &:not(:first-child) {
            //margin-top: var(--size-geo--nm);
          }
        }
      }

    }
  }

  &-layout {
    $navbar-size: 5rem;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    background: #0F1F2F;
    position: relative;
    z-index: 0;

    &.app-layout--with-navigation {
      grid-template-columns: auto 1fr;
    }

    &--with-notification-toggle > &__navigation {
      grid-template-areas: "nav-logo" "nav-notifications-toggle" "nav-perspective" "nav-main" "nav-session";
      grid-template-rows: $navbar-size $navbar-size auto auto 1fr;
    }

    &--no-notifications > &__navigation {
      grid-template-areas: "nav-logo" "nav-perspective" "nav-main" "nav-session";
      grid-template-rows: $navbar-size  auto auto 1fr;
    }

    &__navigation {
      position: relative;
      z-index: 110;
      background: #1A2A39;
      min-width: $navbar-size;
      min-height: $navbar-size;

      display: grid;
      grid-template-columns: 1fr;

      .app-nav {
        position: relative;

        &-logo {
          grid-area: nav-logo;
          width: 2.125rem;
          height: 2.125rem;
          padding: 1.4375rem;
          margin: 0;

          @include fx--pane-fade-down;
        }

        &__perspective,
        &__main {

          > :not(.active):first-child {
            // @todo?
            //border-top: 1px solid #0A1F30;
          }
        }

        &__perspective {
          grid-area: nav-perspective;
          @include fx--pane-fade-in;
          animation-duration: 0.5s;
        }

        &__main {
          grid-area: nav-main;
          @include fx--pane-fade-in;
          animation-duration: 0.5s;
        }

        &__perspective,
        &__main,
        &__session {
          .ui-text {
            display: none !important;
          }

          .ui-icon {
            pointer-events: none;
          }
        }

        &__session {
          grid-area: nav-session;
          @include fx--pane-fade-up;
        }

        &-notifications-toggle {
          grid-area: nav-notifications-toggle;
          @include fx--pane-fade-down;

          &.ui-button {
            padding: 0 !important;
            transform: scale(0.8125);
          }

          .ui-icon {
            pointer-events: none;
          }

          &.active {

            background-color: var(--color-bg--accent);
            color: var(--color-text--inverse-bright);

            &:hover {
              background-color: var(--color-bg--accent-hover);
              color: var(--color-text--inverse-bright);
            }
          }
        }

        &__main,
        &__perspective,
        &__session {
          display: flex;
          flex-direction: column;
        }

        &-notifications-toggle,
        &__perspective > a,
        &__main > a,
        &__session > a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: $navbar-size;
          height: $navbar-size * 0.75;
          text-align: center;
          transition: color 0.175s ease-in-out, background-color 0.175s ease-in-out;
          font-size: 1.625rem;
          background-color: transparent;
          color: var(--color-text--inverse);
          border-radius: 0;
          border-left: 2px solid transparent;
          position: relative;
          margin: 1rem 0 0;

          :not(.app-layout--with-notification-center) > &.active {
            border-left: 2px solid var(--color-bg--accent);
            background: linear-gradient(to right, #3A2035 0%, #0A1F30 100%);
            color: var(--color-text--accent);

            &:hover {
              color: var(--color-text--accent-hover);
            }
          }

          &:not(.active):hover {
            //border-left: 2px solid var(--color-bg--accent);
            //background: linear-gradient(to right, #3A2035 0%, #0A1F30 100%);
            color: var(--color-text--accent-hover);
          }

          > .ui-icon {
            transition: all 0.2s ease-in-out;
            transition-delay: 0ms;
            transform-origin: center center;
            transform: scale(1);
          }

          &.disabled {
            opacity: 0.325;
            cursor: default;

            &:hover {
              color: var(--color-text--inverse);
            }
          }

          &.active {

            > .ui-icon {
              transform: scale(1.125);
            }

            > .app-nav__decor {
              display: block;
            }
          }

          .app-nav__decor {
            display: none;
            position: absolute;
            right: 0;
            left: 0;
            height: 1rem;
            z-index: 100;
            zoom: 1;

            &--before {
              top: -1rem;

              &:after {
                border-bottom-right-radius: 0.5rem;
              }
            }

            &--after {
              bottom: -1rem;

              &:after {
                border-top-right-radius: 0.5rem;
              }
            }

            &:before,
            &:after {
              display: block;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              content: '';
              zoom: 1;
            }

            &:before {
              width: 1rem;
              background: linear-gradient(to right, #3A2035 0%, #0A1F30 100%);
              z-index: 1;
            }

            &:after {
              left: 0;
              background: #1A2A39;;
              z-index: 2;
            }
          }
        }
      }

      &__perspective-switcher {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: auto;

        &__toggle {
          cursor: pointer;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        &__container {
          display: flex;
          flex-direction: column;
          min-width: 20rem;
          margin: 0 0 0.5rem 0;

          padding: 0;
          //opacity: 0;

          &.show {
            //transform: translateY(-100vh);
            top: auto !important;
            bottom: 0 !important;
            opacity: 1 !important;
            margin-left: 0 !important;
            border-radius: 0;
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            border: 1px solid rgba(255, 255, 255, 0.1);
            background-color: #0F1F2F;
            box-shadow: inset -1rem -2rem 5rem rgba(0, 0, 0, 0.2);
          }

          &:after {
            display: none;
          }
        }

        &__item {
          cursor: pointer;
          padding: 0 var(--size-geo--sm) 0 0;
          margin: 0;
          font-size: var(--size-font--nm);

          &:not(:first-of-type) {
            border-top: 1px dashed rgba(255, 255, 255, 0.1);
          }

          &:first-of-type {
            border-top-left-radius: 1rem;
          }

          &:last-of-type {
            border-bottom-right-radius: 1rem;


            &:not(:first-of-type) {
              border-top-style: solid;
            }
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.025);
          }
        }
      }
    }

    &__viewport {
      z-index: 100;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      transform-style: preserve-3d;
      perspective: none;
      will-change: transform;
      position: relative;
    }

    &__notification-center {
      $nc-width: 22.5rem;

      z-index: 105;
      position: fixed;
      top: 0;
      bottom: 0;
      left: $navbar-size;
      width: $nc-width;
      transform: translateX(-1 * $nc-width);
      background: #0F1F2F;
      box-shadow: inset 0 0 2.5rem rgba(0, 0, 0, 0.325);
      display: flex;
      flex-direction: column;
      min-height: 0;
      max-height: 100%;
      overflow: hidden;
    }
  }

  &-notification {
    border-bottom: 1px solid #BE4558;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--size-geo--nm);
    font-size: var(--size-font--sm);
    border-bottom: 1px solid rgba(34, 53, 69, 0.5);
    cursor: pointer;
    background: rgba(255, 255, 255, 0);
    transition: background-color 0.2s ease;
    position: relative;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }

    &__title {
      font-weight: bold;
      opacity: 0.9;
      display: block;
      line-height: 1em !important;
      transition: opacity 0.2s ease, transform 0.2s ease;

      > span {
        font-family: var(--font-sans-serif);
        line-height: 1.4;
      }

      .app-notification:hover & {
        opacity: 1;
        transform: translate3d(0, -0.75em, 0);
      }
    }

    &__message {
      opacity: 0.8125;
      display: block;
      transform: translate3d(0, -0.125em, 0);
      transition: opacity 0.2s ease, transform 0.2s ease;

      .app-notification:hover & {
        opacity: 0.9;
        transform: translate3d(0, -0.875em, 0);
      }
    }

    &__action {
      display: block;
      position: absolute;
      left: var(--size-geo--nm);
      bottom: 0.75em;
      transition: opacity 0.2s ease, transform 0.2s ease;
      opacity: 0;
      transform: translate3d(-1rem, 0, 0);
      font-weight: bold;

      .app-notification:hover & {
        text-transform: uppercase;
        font-size: var(--size-font--xs);
        opacity: 0.8125;
        transform: translate3d(0, 0, 0);
      }
    }

    &-center {
      flex: 1;
      min-height: 0;
      max-height: 100%;
      overflow: hidden;

      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: var(--size-geo--nm);
        border-bottom: 1px solid #223545;
      }

      &__notification-list {
        flex: 1;
        min-height: 0;
        max-height: 100%;
        overflow: auto;
      }
    }

    &--seen {
      opacity: 0.9;
    }

    &--seen &__title {
      font-weight: normal;
    }

    &__bell {
      cursor: pointer;
      position: relative;

      &__unread-count {
        @include fx--pane-fade-in;

        position: absolute;
        top: 0.75em;
        right: 0.75em;
        background: var(--color-bg--accent);
        color: var(--color-text--inverse);
        font-size: var(--size-font--sm);
        width: 1.625em;
        height: 1.625em;
        font-weight: bold;
        border-radius: 50%;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      }

      &.active &__unread-count {
        background: var(--color-text--inverse);
        color: var(--color-bg--accent);
      }
    }
  }

  &-welcome-tour {
    &-buttons {
      margin-top: 40px;
    }
  }

  &-splash-screen {
    display: grid;
    flex: 1;
    max-height: 100%;
    overflow: auto;
    grid-template-rows: 1fr 5fr;
    justify-content: center;
    min-width: 100%;
    max-width: 100vh;

    @include from-screen("tablet") {
      max-width: 24rem;
    }

    &--footer {
      grid-template-rows: 1fr 2fr 1fr;
    }


    &--center {
      align-items: center;
      grid-template-rows: 1fr;
    }

    &--wide {
      //@include from-screen("tablet") {
      //  max-width: 24rem;
      //}
    }

    &__container {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: transparent url('./asset/brand/rooq-welcome-splash.png') center/cover no-repeat;

      &--dim {
        background-image: url('./asset/brand/rooq-welcome-splash-dim.png');
      }
    }

    &__header,
    &__content,
    &__footer {
      display: flex;
      flex-direction: column;
      width: 22.5rem;

      .app-splash-screen--wide & {
        width: 50rem;
      }
    }

    &__header {
      justify-content: flex-end;
    }

    &__content {
      justify-content: flex-start;

      &:not(:last-child) {
        justify-content: flex-end;
      }
    }

    &__footer {
      justify-content: flex-start;
    }
  }

  &-loading-spinner {
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @include fx--spin;
    transform-origin: center;
  }

  &-page {

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include content-box;

      //padding-top: var(--size-geo--xs);
      padding-bottom: var(--size-geo--xs);

      @include from-screen("mobile-l") {
        //padding-top: var(--size-geo--sm);
        padding-bottom: var(--size-geo--sm);
      }

      @include from-screen("laptop") {
        //padding-top: var(--size-geo--nm);
        padding-bottom: var(--size-geo--nm);
      }

      &__back-action {
        font-size: 1.5em;
        margin-right: 1em;
      }
    }

    &-section {

      &-title {
        padding: var(--size-geo--sm) var(--size-geo--nm);
        background-color: var(--color-bg--primary);
        color: #9abbd0;

        &--dock-top {
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }

        &--dock-bottom {
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }

      &-inset {
        @include content-box;
        background-color: rgba(38, 53, 67, 0.9);
      }

      &-panel {
        background-color: #1A2A39;
        box-shadow: 0 0.325rem 0.675rem rgba(0, 0, 0, 0.125);
        color: var(--color-text--inverse);

        &__title {
          font-size: 1.125rem;
          padding: var(--size-geo--sm) var(--size-geo--nm);
          background-color: var(--color-bg--primary);
          color: #9abbd0;
        }

        &--rounded {
          border-radius: 0.3125rem;
        }

        &--rounded &__title {
          border-top-left-radius: 0.3125rem;
          border-top-right-radius: 0.3125rem;
        }
      }
    }

    &-menu {

      &__item {

      }
    }

    &-tos-message {
      margin-top: 1rem;
      text-align: left;
      font-size: 0.75rem;
      color: var(--color-text--default-dim);
      line-height: 1.5;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-panel-message {
    padding: var(--size-geo--nm);

    &--warn {
      background-color: hsla(28, 100%, 61%, 0.125);
      border: 1px solid hsla(28, 100%, 61%, 0.625);
    }

  }

  &-nav {

    &-button {

      &.ui-button--ghost.ui-button--clr-default,
      &.ui-button--semantic.ui-button--clr-default,
      &.ui-button--outlines.ui-button--clr-default {

        &:not(:hover) {
          color: var(--color-text--inverse-dim) !important;
        }

        &:hover {
          color: var(--color-text--inverse-hover);
        }
      }
    }
  }

  &-tab-navigation {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding-top: 0.125em;

    @include content-hbox;

    > * {
      position: relative;
      padding: 0.625em 1.5em;
      border: 0;
      border-radius: 0;
      font-family: var(--font-title);
      color: var(--color-text--inverse);
      opacity: 0.625;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:after {
        display: block;
        content: '';
        zoom: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: 0.1em;
        background-color: #fff;
        transition: opacity 0.175s linear, transform 0.175s linear;
        transform-origin: center;
        opacity: 0;
        transform: translate3d(0, 0.125em, 0) scale(0.25);
      }

      &.active {
        opacity: 1;
        font-weight: bold;

        &:after {
          opacity: 1;
          transform: translate3d(0, 0, 0) scale(1);
        }
      }

    }

    &--accent {
      background-color: var(--color-bg--accent);

      > * {

      }
    }
  }

  &-card {
    border-radius: 0.3125em;
    background-color: var(--color-bg--primary);
    padding: 1.3125em;
  }

  &-banner-pane {
    position: relative;
    overflow: hidden;
    z-index: 0;
    --banner-pane-tint: #0A1F30;

    transform: translate3d(0, 0, 0);

    &__decor {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.25s ease, opacity 0.25s ease;
      opacity: 0;
      background-color: transparent;
      background-image: none;
      z-index: 10;
    }

    &--loaded &__decor {
      opacity: 1;
    }

    &__image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transform-origin: center;
      transition: box-shadow 0.25s ease, transform 0.25s ease, opacity 0.25s ease;
      box-shadow: inset 0 0 2rem 0.25rem rgba(15, 32, 47, 0.75);
      transform: scale(1);
      z-index: 5;
      opacity: 0;
    }

    &__foregroundimage {
      position: absolute;
      top: calc(50% - 25px);
      bottom: unset;
      left: 50%;

      width: 42%;
      height: auto;
      border: 5px solid var(--color-rooq--primary);
      outline: 4px solid #5d6f7e;

      border-radius: 50%;
      background-size: cover;
      background-position: center;

      transform: translate(-50%, -50%);

      &::before {
        position: relative;

        display: block;
        padding: 0 0 100%;
        width: 100%;
        height: 0;

        content: '';
      }
    }

    &__gradient-1 {
      background: linear-gradient(to bottom, rgba(10, 31, 48, 0) 0%, rgba(10, 31, 48, 0.15) 50%, rgba(10, 31, 48, 0.97) 100%);
    }

    &__gradient-2 {
      background: linear-gradient(180deg, rgba(10, 31, 48, 0) 0%, #0A1F30 100%);
      opacity: 0.75;
    }

    &__gradient-3 {
      background: linear-gradient(to top, #0A1F30 0%, rgba(10, 31, 48, 0) 4.25rem, rgba(10, 31, 48, 0) 100%);
    }

    &__content {
      position: absolute;
      z-index: 15;
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;

      &--c {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      &--b {
        &l {
          left: 0;
          bottom: 0;
        }

        &s {
          left: 0;
          right: 0;
          bottom: 0;
        }

        &r {
          right: 0;
          bottom: 0;
        }
      }

      &--t {
        &l {
          left: 0;
          top: 0;
        }

        &r {
          right: 0;
          top: 0;
        }

        &s {
          left: 0;
          right: 0;
          top: 0;
        }
      }
    }
  }

  &-email-action,
  &-onboarding {

    &__welcome {

      .app-splash-screen {
        overflow: hidden;

        @include from-screen("laptop") {
          max-width: 32rem;
        }
      }

    }

    &__complete {

      > .app-splash-screen {
        grid-template-rows: 1fr 5fr;
      }

      > .app-splash-screen.app-splash-screen--footer {
        grid-template-rows: 1fr 4fr 1fr;
      }
    }

    &__complete,
    &__register,
    &__reset-password,
    &__login {

      .app-splash-screen__content {
        padding-top: var(--size-geo--md);
        //overflow: auto;
      }

      .ui-form {

        .ui-form-footer .ui-button,
        footer .ui-button {
          &:first-of-type {
            margin-top: var(--size-geo--md);
          }

          &:last-of-type {
            margin-bottom: var(--size-geo--xs);
          }
        }

        .form-field__control,
        input.form-field__control {
          min-width: 16rem;

          @include from-screen("mobile-s") {
            min-width: 20rem;
          }

          @include from-screen("mobile-l") {
            min-width: 24rem;
          }

          @include from-screen("tablet") {
            min-width: 26rem;
          }

          @include from-screen("desktop") {
            min-width: 30rem;
          }
        }
      }

    }

    .app-logo-initial-image {
      max-height: 7.5vh;
      align-self: flex-start;
    }

    .app-logo-image {
      max-height: 10vh;
    }

    .app-subline-image {
      max-height: 1rem;
      margin-top: var(--size-geo--nm)
    }
  }

  &-dashboard {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    &__grid {
      display: grid;
      position: relative;
      min-height: 100%;
      max-height: initial;
      overflow: hidden;
      flex: 1;
      grid-gap: var(--size-geo--nm);
      grid-template-rows: repeat(6, minmax(min-content, 1fr));
      grid-template-columns: repeat(2, minmax(0, 1fr));

      > * {
        min-height: 7.5rem;

        &:first-child,
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          grid-column: 1/3;
        }
      }

      @include from-screen("laptop") {
        grid-gap: var(--size-geo--md);
      }

      @include from-screen("desktop") {
        grid-gap: var(--size-geo--md);
        grid-template-rows: repeat(3, minmax(min-content, 1fr));
        grid-template-columns: repeat(4, minmax(0, 1fr));

        > * {
          &:first-child {
            grid-column: 1/3;
          }

          &:nth-child(6) {
            grid-column: 3/5;
          }

          &:nth-child(7) {
            grid-column: 1/3;
          }

          &:nth-child(8) {
            grid-column: 3/5;
          }
        }
      }

      &--fx {
        .app-dashboard__card {
          opacity: 1;
        }
      }
    }

    &__card {
      display: block;
      padding: 0;
      border-radius: 0.4125rem;
      z-index: 0;
      position: relative;
      overflow: hidden;

      > .app-banner-pane {
        border-radius: inherit;
        height: 100%;
        padding-bottom: 0 !important;
        background: rgba(145, 162, 181, 0.09);

        &__image {
          box-shadow: none;
        }

        &--loaded {
          background: rgba(145, 162, 181, 0.68);

          > .app-banner-pane__image {
            opacity: 0.825;
            box-shadow: inset 0 1rem 1rem 0 rgba(164, 196, 224, 0.125);
          }

          > .app-banner-pane__gradient-1 {
            opacity: 0.625;
          }

          > .app-banner-pane__gradient-2,
          > .app-banner-pane__gradient-3 {
            opacity: 0.1;
          }
        }

        &:hover {
          > .app-banner-pane__image {
            opacity: 0.925;
            box-shadow: inset 0 1rem 2.5rem 0 rgba(164, 196, 224, 0.4125);
          }
        }
      }

      > .app-banner-pane > .app-banner-pane__content {
        @include content-box;
      }

      &__chart {
        border-radius: inherit;

        &__container {
          border-radius: inherit;
          position: relative;
          width: 100%;
          height: 100%;
        }
      }

      &__notification-indicator {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 105;
        padding: 0.825em 1.25em !important;

        > .ui-text {
          text-transform: none;
          font-weight: bold;
          transform-origin: center;
          transform: scale(1.325);
          transition: all 0.2s ease-in-out;
        }

        &:hover > .ui-text {
          transform: scale(1.625);
        }

        border-top-left-radius: 0 !important;
        border-top-right-radius: inherit !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: inherit !important;
      }

      &__progress-indicator {
        border-radius: inherit;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 102;
        transition: transform 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6), opacity 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);

        &__bg-bar,
        &__value-bar {
          border-bottom-left-radius: inherit;

          &:not(:last-child) {
            border-bottom-right-radius: inherit;
          }
        }

        &__bg-bar {
          box-shadow: inset 0 1px 3px 0 rgba(0, 2, 3, 0.25);
        }

        &__value-bar {
          transition: width 0.325s cubic-bezier(0.16, 1, 0.3, 1), background 0.325s cubic-bezier(0.16, 1, 0.3, 1);
          //box-shadow: inset 0 0 2px 1px $rooq-accent--dark;
          background: transparent linear-gradient(to right, rgba(205, 21, 64, 0.4125) 0%, rgba(205, 21, 64, 0.625) 50%, rgba(248, 17, 57, 1) 85%, rgba(205, 21, 64, 0.825) 100%);
          animation-name: dashboard-card__progress-indicator;
          animation-duration: 0.5s;
          animation-iteration-count: infinite;
        }

        &--complete &__value-bar {
          border-bottom-right-radius: inherit;
          background: transparent linear-gradient(to right, var(--color-bg--success-hover) 0%, var(--color-bg--success) 50%, var(--color-bg--success-hover) 100%);
        }

        &--empty &__value-bar {
          border-bottom-right-radius: inherit;
          background: transparent none;
        }

        &--complete {
          transform: translate3d(0, 0.5em, 0);
          opacity: 0;
          transition-delay: 750ms;
          transition-duration: 420ms;
        }
      }

      &--hardware-info {

        > .app-banner-pane {
          background: rgba(145, 162, 181, 0.025);
        }
      }
    }
  }

  &-user {

    &-account {

      &-picture {
        display: block;
        width: var(--width);
        max-width: none;
        height: var(--height);
        margin: 0;
        padding: 0;
        border: 0;
        background-color: rgba(0, 0, 0, 0.1);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
      }
    }
  }

  &-account {
    &__tile-listing {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: 18.25rem;
    }

    &__tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 8.25rem;
      height: 8.25rem;
      background-color: #1A2A39;
      cursor: pointer;
      border-left: 2px solid #121F2E;
      border-top: 2px solid #121F2E;
      position: relative;
      transition: all 0.1625s ease-in-out;

      &:hover {
        background-color: $rooq-primary--lighter;
      }

      &--action {
        background-color: #0C1A27;

        &, &:not(:first-child) {
          border-left-width: 0;
        }

        &:hover {
          background-color: $rooq-primary--lighter;
        }
      }

      &__tooltip-menu {
        position: absolute;
        top: 0;
        right: 0;

        .ui-tooltip-menu__toggle {
          margin-top: 0.25rem;
          margin-right: 0.25rem;
          padding: 0.25rem;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.1);
        }
      }

      .app-user-account-picture,
      .ui-icon__userProfile {
        width: 60px;
        height: 60px;

        outline: 2px solid rgba(255, 255, 255, 0.1);
        outline-offset: 3px;
      }
    }

  }

  &-fight-style {

    &-select-control {
      display: flex;
      flex-direction: column;

      &__option {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.0);
        transition: background-color 0.2s ease, opacity-color 0.2s ease;
        padding: var(--size-geo--sm) var(--size-geo--nm);
        display: flex;
        flex-direction: row;
        align-items: center;
        opacity: 0.75;

        &:hover {
          background: rgba(255, 255, 255, 0.075);
        }

        &:not(.app-trainer-fight-style-select-control__option--checked) {

          &:hover {
            opacity: 0.825;
          }
        }

        &:first-child {
          border-top-left-radius: 0.25em;
          border-top-right-radius: 0.25em;
        }

        &:last-child {
          border-bottom-left-radius: 0.25em;
          border-bottom-right-radius: 0.25em;
        }

        &:not(:last-child) {
          border-bottom: 1px dashed rgba(255, 255, 255, 0.25);
        }

        &--checked {
          opacity: 1;
          font-weight: bold;

          > .ui-icon {
            color: var(--color-text--success);
          }
        }

        .ui-text {
          flex: 1;
          padding-right: var(--size-geo--sm);
        }
      }
    }
  }

  &-trainer {

    &-no-connections-interstitial {
      height: 100%;
      width: 100%;
      max-height: 100%;
      overflow: hidden;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: var(--size-geo--md);
      grid-template-areas: "cta search" "pending search";

      &__cta {
        grid-area: cta;
      }

      &__pending {
        grid-area: pending;

        .app-account__tile-listing {
          margin-right: 0;
        }
      }

      &__search {
        grid-area: search;
        max-height: 100%;
        overflow: hidden;

        .app-search-modal {
          min-height: 100% !important;
          max-height: 100% !important;
          height: 100% !important;
        }
      }
    }
  }


  &-shop-locked-seat-card,
  &-shop-free-seat-card,
  &-shop-trial-gift-card,
  &-shop-free-seat-explainer-card,
  &-athlete-stat-card,
  &-trainer-card {
    border-radius: 0.25em;
    box-shadow: 0 0.3125rem 1rem rgba(0, 0, 0, 0.125);
    position: relative;

    &__header {
      border-top-left-radius: 0.25em;
      border-top-right-radius: 0.25em;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      .app-banner-pane {
        &__decor {
          transition: background-color 0.25s ease;
        }

        &__image {
          transform-origin: center;
          transition: box-shadow 0.25s ease, transform 0.25s ease;
          box-shadow: inset 0 0 2rem 0.25rem rgba(15, 32, 47, 0.75);
          transform: scale(1);

          @include fx--pane-fade-in;
        }
      }

      .app-user-account-picture {
        border: 2px solid var(--color-rooq-border--outer);
        box-shadow: inset 0 0 2px 2px var(--color-rooq-border--inner);
      }

      &:not(.app-shop-locked-seat-card):hover .app-banner-pane {
        &__image {
          box-shadow: inset 0 0 2rem 0.25rem rgba(68, 129, 183, 0.75);
        }

        &__gradient-2 {
          background: linear-gradient(180deg, rgba(10, 31, 48, 0) 0%, rgba(12, 37, 57, 0.8) 100%);
        }
      }

      &__favbutton {
        $favbutton-size: 2rem;

        position: absolute;
        top: $favbutton-size * -0.25;
        right: $favbutton-size * -0.25;
        border-radius: 50% !important;
        padding: 0 !important;
        width: $favbutton-size;
        height: $favbutton-size;
        align-items: center;
        justify-content: center;
        border: 0.25em solid #111D2B; // bit hacky, grabbed from sceen hehe
        transform-origin: center;
        transform: scale(1);
        transition: transform 0.25s ease;

        &.ui-button--semantic.ui-button--clr-default {
          color: var(--color-text--primary) !important;
          background-color: var(--color-bg--default);

          &:hover {
            color: var(--color-text--primary-hover) !important;
            background-color: var(--color-bg--default-hover);
          }

          &.active {
            color: var(--color-text--inverse) !important;
            background-color: var(--color-bg--accent);

            &:hover {
              color: var(--color-text--inverse-hover) !important;
              background-color: var(--color-bg--accent-hover);
            }
          }
        }

        &:hover {
          transform: scale(1.075);
        }
      }

      &:hover {
      }

      .ui-text-title {
        font-size: 1.5rem;
        padding: var(--size-geo--nm);
      }
    }

    &__content {
      padding: var(--size-geo--nm);
      border-bottom-left-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
      background-color: #1A2A39;

      > ul {
        margin-right: var(--size-geo--nm);
      }

      .ui-button.ui-button--ghost.ui-button--clr-default {
        background-color: rgba(255, 255, 255, 0.05);

        > .ui-icon {
          transform: scale(1.75);
          transition: transform 0.15s ease;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.125);

          > .ui-icon {
            transform: scale(1.825);
          }
        }
      }

      .ui-button > .ui-icon:only-child > svg {
        transform-origin: center;
        transform: scale(1.5);
      }
    }

    &__action {
      position: absolute;
      top: 0;
      bottom: auto;
      background-color: var(--color-bg--primary);
      color: var(--color-text--inverse-dim);


      &:hover {
        background-color: var(--color-bg--primary-hover);

        .ui-icon {
          color: var(--color-text--inverse-dim);
        }
      }

      &--top-left {
        left: 0;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      &--top-right {
        right: 0;
        border-top-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .ui-icon {
        font-size: 1.25rem;
        width: 2.25rem !important;
        height: 2.25rem !important;
        color: var(--color-text--inverse-dimmer);
      }
    }
  }

  &-shop-locked-seat-card {

    .app-banner-pane {
      pointer-events: none;

      &__gradient-1 {
        background: linear-gradient(to bottom, rgba(10, 31, 48, 0) 0%, rgba(48, 10, 13, 0.15) 50%, rgba(48, 10, 28, 0.97) 100%);
        opacity: 0.25;
      }

      &__gradient-2 {
        background: linear-gradient(180deg, rgba(74, 6, 45, 0) 0%, #692641 100%);
        opacity: 0.5;
      }

      //
      &__gradient-3 {
        opacity: 0.25;
        //background: linear-gradient(180deg, rgba(74, 6, 45, 0) 0%, #692633 100%);
        //  background: linear-gradient(to top, #40262d 0%, rgba(50, 18, 35, 0) 4.25rem, rgba(48, 10, 28, 0) 100%);
      }
    }
  }

  &-shop-trial-gift-card {

    &__indicator {
      position: relative;
      width: 7.5rem;
      height: 7.5rem;
      background: url("/asset/placeholder/card-icon-bg-tr.svg");
      background-size: cover;
      padding-top: var(--size-geo--sm);
      padding-right: var(--size-geo--sm);

      .ui-icon {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  &-shop-free-seat-explainer-card {
    display: flex;
    flex-direction: column;

    &__content {
      padding: var(--size-geo--nm);
      border-bottom-left-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
      background-color: transparent;
      border: 2px solid rgba(255, 255, 255, 0.1);
    }
  }

  &-shop-free-seat-card {
    &__header {
      background: url('./asset/placeholder/rooq-spint-open.jpg') center/cover transparent;
    }
  }

  &-athlete {

    &-modal-card {
      border-radius: 0.325rem;
      background-color: var(--color-bg--primary-dark);
      color: var(--color-text--inverse);
      display: flex;
      flex-direction: column;
      @include modal-single-col;

      > *:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }

      > *:last-child {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }

      &__close.ui-button {
        padding: 0 !important;
        border-radius: 50% !important;
        position: absolute;
        top: 0.325em;
        right: 0.325em;
        width: 2.25em;
        height: 2.25em;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index: 10;

        > .ui-icon {
          margin: 0 0 0 0.1em !important;
          transform: scale(1.5);
        }
      }

      .app-banner-pane__foregroundimage {
        width: 38%;
      }

      .app-page-section-panel {
        border-radius: 0;
        box-shadow: none;
      }

      .app-banner-pane {
        z-index: 10;
      }

      .app-tab-navigation {
        z-index: 5;
      }

      .app-banner-pane .ui-page-segment {
        margin: var(--size-geo--nm);
      }

      .ui-page-content,
      .app-page-section-panel > .ui-page-content {
        padding: var(--size-geo--nm);
      }

      .app-page-section-panel > .ui-page-content + .ui-page-content {
        padding-top: 0;
      }
    }

    &-search-modal {
      @include modal-single-col;

    }

    &__list {

      &__item {

        &:not(:last-of-type) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.075);
        }
      }
    }

    &-onboading-tooltips {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--size-geo--nm);
    }
  }

  &-analysis {

    &__drilldown {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-row-gap: var(--size-geo--nm);
    }

    &__drilldown > &__details-segment {
      //scroll-snap-align: start;
    }

    &-training-session {


      &-breakdown {
        display: inline-flex;
        flex-direction: row;
        font-size: 0.75rem;

        &__item {
          display: flex;
          flex-direction: column;
          padding: 0.5em;
          min-height: 6.25em;

          &__bar {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: 0.5em;

            &__fill {
              width: 100%;
              background: linear-gradient(180deg, #94ADC1 0%, rgba(148, 173, 193, 0.12) 100%);
              border-top-right-radius: 0.25em;
              border-bottom-left-radius: 0.25em;
            }
          }

          &__icon {
            > img {
              height: 1.125em;
              width: 1.125em;
            }
          }
        }

        &__item2 {
          position: relative;
          padding-right: 0.75rem;

          &:not(:last-of-type) {
            margin-right: 0.75rem;

            &:after {
              display: block;
              pointer-events: none;
              content: '';
              zoom: 1;
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              width: 1px;
              background: linear-gradient(to bottom, rgba(34, 53, 69, 0%) 0%, #223545 100%);
            }
          }

          .app-analysis-training-session-pie-chart {

            > svg {
              transform-origin: center;

              > image {
                transform-origin: center;
                transform: scale(0.625);
                opacity: 0.625;
              }
            }
          }
        }
      }

      &-pie-chart {

      }
    }


    &__details {

      &__container {
        position: relative;
        padding: var(--size-geo--nm);
        max-height: 100%;
        overflow-y: scroll;
        //scroll-snap-type: y mandatory;

        &.sticky {
          z-index: 10;
        }
      }

      &-page {
        padding: var(--size-geo--nm);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        grid-row-gap: var(--size-geo--nm);
        flex: 1;
        align-items: start;
      }

      //&-page > &-segment {
      //  scroll-snap-align: start;
      //}
    }

    &-details {

      &-header {
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        //max-height: 25rem;
        //min-height: 10rem;
        flex: 0 0 auto;
        top: 0;
        transition: all 0.2s ease-in-out;
        z-index: 20;
        background-color: rgba(0, 0, 0, 0);
        border-bottom: 1px solid transparent;
        //margin-top: var(--size-geo--nm);

        &.app-banner-pane {
          display: flex;
          flex-direction: row;
          flex: 0 0 auto;
          position: relative;
          bottom: unset;
        }


        &.sticky {
          z-index: 20;
          align-items: center;
          flex: 0 0 auto !important;
          min-height: 0;
          position: sticky;
          margin-top: 0;
          top: calc(-1px - var(--size-geo--nm)) !important;
          background-color: desaturate(lighten($rooq-primary--dark, 7.5%), 5%);
          border-radius: 0;
          border-bottom-color: #10293c;

          .app-banner-pane__image {
            display: none;
          }
        }

        &__title {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          flex: 1;
          padding: var(--size-geo--nm);

          > .ui-text-title {
            transition: all 0.2s ease-in-out;
            font-size: 2.625rem;
          }
        }

        &__summaries {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: var(--size-geo--lg) var(--size-geo--nm) var(--size-geo--nm);

          .app-analysis-athlete-summary {
          }
        }

        &.sticky &__title {
          justify-content: center;
          padding: 0 var(--size-geo--nm) !important;

          > .ui-text-line {
            display: none !important;
          }

          > .ui-text-title {
            padding: var(--size-geo--sm) 0;
            font-size: 1.65rem;
          }
        }

        &.sticky &__summaries {
          flex-direction: row;
          align-items: center;
          padding: var(--size-geo--sm) var(--size-geo--nm);

          .app-analysis-athlete-summary {
            align-items: center;
            grid-template-columns: 2rem auto;
            grid-template-areas: "avatar stats";
            grid-row-gap: 0.05em;
            grid-column-gap: var(--size-geo--sm);
            margin-top: 0;

            & + .app-analysis-athlete-summary {
              margin-left: var(--size-geo--md);
            }

            &__avatar,
            &__avatar > .ui-icon {
              font-size: 2rem !important;
              width: 2rem !important;
              height: 2rem !important;
            }

            &__avatar > .ui-icon {
              transform: translate3d(-10%, -5%, 0);
            }

            &__stats {
              align-items: center !important;

              > .ui-text:not(:last-child) {
                margin-right: var(--size-geo--sm);
              }
            }

            &__progressbar, &__branding {
              display: none !important;
            }
          }
        }

        .app-banner-pane__content {
          flex: 1;
          position: relative;
          top: unset;
          display: flex;
          flex-direction: row;
        }
      }

      &-tabs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 2px;
        grid-template-rows: 5rem;
        border-bottom: 1px solid #1B2939;

        &__item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: var(--size-geo--sm) var(--size-geo--nm);
          background: rgba(10, 31, 48, 0.5);
          border-top: 2px solid transparent;

          .ui-text-title {
            font-size: var(--size-font--md);
            color: var(--color-text--inverse-dim);
            margin-bottom: var(--size-geo--xs);
            transform-origin: center;
            transform: translateY(1rem);
            transition: transform 0.2s ease, opacity 0.2s ease;
          }

          &:hover:not(.active) {
            background: rgba(22, 42, 58, 0.5);
          }

          &.active {
            background: rgba(22, 42, 58, 1);
            border-top-color: var(--color-bg--accent);

            .ui-text-title {
              color: var(--color-text--inverse);
              transform: translateY(0);
            }
          }

          &:not(.active) {

          }

          &__content {

            &__active,
            &__stats {
              height: 1.5rem;
            }

            &__active {
              //display: none;
            }

            align-items: center;
            opacity: 0;
            z-index: -1;
            transform-origin: center;
            transform: translateY(3rem);
            transition: transform 0.3s ease-in-out, opacity 0.3s ease 0.1s;

            &__stats {
              flex: 1;
              display: flex;
              margin-left: -1rem;
              flex-wrap: wrap;
            }
          }

          &.active &__content {
            display: initial;
            opacity: 1;
            transform: translateY(0);
            z-index: 1;
          }

          &.active &__content__stats {
            display: none;
            margin-left: 0;
          }
        }
      }

    }

    &-athlete-summary {
      display: grid;
      grid-template-areas:
          "avatar stats stats"
          "avatar progress progress"
          "avatar brand brand";
      grid-template-columns: 3rem minmax(10rem, 16rem) auto;
      grid-column-gap: 1rem;
      grid-row-gap: 0.325rem;

      & + & {
        margin-top: 1.5rem;
      }

      &__avatar {
        grid-area: avatar;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid transparent;

        > .ui-icon,
        > .app-user-account-picture {
          border-radius: 50%;
          overflow: hidden;
        }

        > .ui-icon {
          transform: translateX(-7.5%);
          opacity: 0.865;
        }
      }

      &--0 &__avatar {
        border: 3px solid transparentize($rooq-session--1, 0.25);

        > .ui-icon {
          //color: $rooq-session--1;
        }
      }

      &--0 &__progressbar > .app-dashboard__card__progress-indicator > .app-dashboard__card__progress-indicator__value-bar {
        @include bg-bar-gradient($rooq-session--1-r, $rooq-session--1-g, $rooq-session--1-b);
      }

      &--1 &__avatar {
        border: 3px solid transparentize($rooq-session--2, 0.25);

        > .ui-icon {
          //color: $rooq-session--2;
        }
      }

      &--1 &__progressbar > .app-dashboard__card__progress-indicator > .app-dashboard__card__progress-indicator__value-bar {
        @include bg-bar-gradient($rooq-session--2-r, $rooq-session--2-g, $rooq-session--2-b);
      }

      &--2 &__avatar {
        border: 3px solid transparentize($rooq-session--3, 0.25);

        > .ui-icon {
          //color: $rooq-session--3;
        }
      }

      &--2 &__progressbar > .app-dashboard__card__progress-indicator > .app-dashboard__card__progress-indicator__value-bar {
        @include bg-bar-gradient($rooq-session--3-r, $rooq-session--3-g, $rooq-session--3-b);
      }

      &__stats {
        grid-area: stats;
      }

      &__progressbar {
        grid-area: progress;
        position: relative;
        height: 0.5rem;
        border-radius: 0.5rem;

        > .app-dashboard__card__progress-indicator,
        > .app-dashboard__card__progress-indicator > .app-dashboard__card__progress-indicator__value-bar,
        > .app-dashboard__card__progress-indicator > .app-dashboard__card__progress-indicator__bg-bar {
          border-radius: 0.5rem;
        }
      }

      &__branding {
        grid-area: brand;
      }
    }

    &__panel {
      display: flex;
      flex-direction: column;
      background: rgba(10, 31, 48, 0.5);
      border-radius: 0.425rem;
      max-height: 100%;
      overflow: hidden;
      position: relative;
      z-index: 5;

      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: var(--size-geo--sm) var(--size-geo--nm) calc(1px + var(--size-geo--sm));
        position: relative;
        z-index: 2;
        @include light-border-bottom-gradient(3);

        //border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        &:not(.app-analysis__panel__header--titled) {
          justify-content: center !important;
        }

        .ui-button-group {

          .ui-button:not(.active):not([disabled]):hover {
            color: var(--color-text--inverse) !important;
          }
        }
      }

      &__body {
        display: flex;
        flex-direction: column;
        padding: var(--size-geo--sm) var(--size-geo--nm);
        flex: 1;
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;
      }
    }

    &__chart {

      &--icon-stats {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: var(--size-geo--nm);

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 2rem 0;

          &__image {
            display: block;
            width: 3.25rem;
            height: 3.25rem;

            @include from-screen("tablet") {
              width: 4.25rem;
              height: 4.25rem;
            }

            @include from-screen("desktop") {
              width: 6.5rem;
              height: 6.5rem;
            }
          }

          &__value {
            font-size: 2rem;
            padding: 1rem 0 0.5rem;
          }

          &__label {
            font-size: var(--size-font--sm);
            color: var(--color-text--default-dim);
          }
        }
      }

      &--lr-unit {
        display: flex;
        flex-direction: column;

        &__title {
          padding-bottom: var(--size-geo--xs);
          margin-top: var(--size-geo--sm);
          font-size: var(--size-font--sm);
          font-weight: 500;
        }

        &__content {
          padding: 0 0 var(--size-geo--sm) 0;

          .app-analysis__chart__simple-bar {
            width: 100%;

            > &__value-bar,
            > &__bg-bar {
              will-change: auto;
              transition: width 0.4s ease-in-out;
            }
          }
        }

        &__footer {
          padding-top: var(--size-geo--sm);
          position: relative;
          z-index: 2;
          @include light-border-top-gradient(3);
        }

        .app-analysis__chart--multi & {
          padding: var(--size-geo--nm) var(--size-geo--nm);
        }
      }

      &--bar {
        &__svg {
          //margin-bottom: var(--size-geo--nm);

          .grid {

            &__columns {

              > .visx-line:first-child {
                opacity: 0;
              }
            }
          }

          &__bar {
            transform-origin: center;
            //transition: all 0.2s ease;

            &.clickable {
              stroke-width: 2px;
              stroke: transparent;
              transition: stroke 0.2s ease;

              &:not(.active):hover {
                cursor: pointer;
                stroke-width: 2px;
                stroke: var(--color-bg--accent-hover);
              }
            }
          }
        }
      }

      &--bar-segment {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: var(--size-geo--nm);
        grid-row-gap: var(--size-geo--nm);
        padding-top: var(--size-geo--nm);
        align-items: center;
        position: relative;
        z-index: 0;

        &.with-data-label:not(.with-data-aggregate) {
          grid-template-columns: auto 1fr;
        }

        &.with-data-aggregate:not(.with-data-label) {
          grid-template-columns: 1fr auto;
        }

        &.with-data-label.with-data-aggregate {
          grid-template-columns: auto 1fr auto;
        }

        &__container {
          z-index: 20;

          &__item {
            display: flex;
            flex-direction: column;
            padding: 0 var(--size-geo--xs);
            border-radius: 0.25em;

            &:not(:first-child) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }

            &:not(:last-child) {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            &--regular {
            }

            &--solid {
            }

            > .ui-text {
              padding: 0.425rem 0;
              //transform: translateY(0);
              //transform-origin: center;
            }

            &.clickable {
              cursor: pointer;

              > .ui-text {
              }

              &:hover > .ui-text {
                transition: all 0.2s ease;
                color: #fff !important;
                border-top-right-radius: 0.5em;
                border-top-left-radius: 0.5em;
                transform: translateY(0);
              }

              &:hover > figure,
              &:hover > figure * {
                transition: all 0.2s ease;
                border-top-right-radius: 0 !important;
                border-top-left-radius: 0 !important;;
              }

              &[data-discipline="SHADOW"]:hover > .ui-text {
                background-color: transparentize($rooq-chart--1, 0.5);
              }

              &[data-discipline="ROPE"]:hover > .ui-text,
              &[data-discipline="ROPE_SKIPPING"]:hover > .ui-text {
                background-color: transparentize($rooq-chart--2, 0.5);
              }

              &[data-discipline="PARTNER"]:hover > .ui-text {
                background-color: transparentize($rooq-chart--3, 0.5);
              }

              &[data-discipline="EQUIPMENT"]:hover > .ui-text {
                background-color: transparentize($rooq-chart--4, 0.5);
              }

              &.routed {

                > .ui-text {
                  position: relative;
                  transition: all 0.2s ease;
                  color: #fff !important;
                  border-top-right-radius: 0.5em;
                  border-top-left-radius: 0.5em;
                }

                > figure,
                > figure * {
                  transition: all 0.2s ease;
                  border-top-right-radius: 0 !important;
                  border-top-left-radius: 0 !important;;
                }

                &[data-discipline="SHADOW"] > .ui-text {
                  background-color: $rooq-chart--1;
                }

                &[data-discipline="ROPE"] > .ui-text,
                &[data-discipline="ROPE_SKIPPING"] > .ui-text {
                  background-color: $rooq-chart--2;
                }

                &[data-discipline="PARTNER"] > .ui-text {
                  background-color: $rooq-chart--3;
                }

                &[data-discipline="EQUIPMENT"] > .ui-text {
                  background-color: $rooq-chart--4;
                }
              }
            }
          }
        }

        &__legend {
          position: relative;
          z-index: 20;
          @include light-border-top-gradient(22);
          padding-top: var(--size-geo--sm);
          padding-bottom: var(--size-geo--nm);
          margin-top: var(--size-geo--sm);

          &__disciplines {
            flex-wrap: wrap;
            padding-top: var(--size-geo--sm);

            &__item {
              padding: 0 var(--size-geo--sm);
              margin-right: var(--size-geo--sm);
              font-size: var(--size-font--sm);
              color: var(--color-text--default-dim);
            }
          }
        }

        &__decor {
          position: absolute;
          z-index: 10;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      &--pie,
      &--double-pie {
        flex: 1;
        margin: var(--size-geo--nm) 0;

        &__legend {
          justify-content: center;

          &__item {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-column-gap: var(--size-geo--sm);
            grid-row-gap: 0.125rem;
            align-items: center;
            margin-bottom: var(--size-geo--sm);
          }
        }

        .app-analysis__chart--multi & {
          padding: var(--size-geo--nm) var(--size-geo--nm);
        }
      }

      &--multi {
        .app-analysis__panel {
        }

        .app-analysis__panel__header {
          justify-content: flex-start;

          &__title {
            padding-right: var(--size-geo--nm);
            border-right: 1px solid var(--color-text--default-dim);
          }
        }

        .ui-button-group {
          max-width: 100%;
          min-width: 0;
          overflow: hidden;

          > .ui-button {
            margin-left: var(--size-geo--nm);
            padding: var(--size-geo--xs) var(--size-geo--sm);
            font-family: var(--font-title);
            color: var(--color-text--default-dim) !important;
            text-transform: none;
            font-size: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            //width: 10rem;

            &.active {
              background-color: rgba(255, 255, 255, 0.05);
              color: var(--color-text--accent) !important;
              border-radius: 0.25rem !important;
            }
          }
        }
      }

      &--line {
        margin-top: var(--size-geo--nm);

        &__legend {
          padding: var(--size-geo--sm) var(--size-geo--nm) var(--size-geo--sm) 1.75rem;

          &__item {
            display: grid;
            grid-template-columns: 0.75rem 1fr;
            grid-column-gap: 0.425rem;
            grid-row-gap: 0.125rem;
            align-items: start;
            margin-bottom: var(--size-geo--sm);
          }
        }
      }

      &--multi-line {
        margin-top: var(--size-geo--nm);

        &__legend {
          padding: var(--size-geo--sm) 0;

          &__item {
            font-size: var(--size-font--sm);

            > *:not(:last-child) {
              margin-right: var(--size-geo--nm);
            }
          }
        }

        &__line {

          &--color-0 {
            stroke: $rooq-session--1;
          }

          &--color-1 {
            stroke: $rooq-session--2;
          }

          &--color-2 {
            stroke: $rooq-session--3;
          }
        }
      }


      &--line,
      &--multi-line {
        .visx-group {

          .segments {
            transform-origin: center bottom;

            &__line {
              transform-origin: center bottom;
            }

            &__dots {
              transform-origin: center;
            }
          }
        }
      }

      &__simple-bar {
        width: 100%;
        height: 6px;
        position: relative;
        border-radius: 6px;

        &__value-bar,
        &__bg-bar {
          height: inherit;
          display: block;
          position: absolute;
          top: 0;
          border-radius: inherit;
        }

        &--ltr &__bg-bar,
        &--ltr &__value-bar {
          transform-origin: left center;
        }

        &--rtl &__bg-bar,
        &--rtl &__value-bar {
          transform-origin: right center;
        }

        &__bg-bar {
          left: 0;
          right: 0;
        }

        &__value-bar {
          //box-shadow: inset -1px 2px 2px 1px rgba(255, 255, 255, 0.125);
        }

        &--ltr &__bg-bar {
          box-shadow: inset -1px 2px 2px 0 rgba(0, 0, 0, 0.125);
          background: linear-gradient(to right, rgba(0, 0, 0, 0.325) 0%, rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.05) 100%);
        }

        &--rtl &__bg-bar {
          box-shadow: inset 1px 2px 2px 0 rgba(0, 0, 0, 0.125);
          background: linear-gradient(to right, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.2) 90%, rgba(0, 0, 0, 0.325) 100%);
        }

        &--ltr &__value-bar {
          left: 0;
        }

        &--rtl &__value-bar {
          right: 0;
        }
      }
    }

    &__session-athlete-label {
      display: inline-flex;
      flex-direction: row;
      align-items: center;

      &__dot {
        margin-right: var(--size-geo--sm);
      }

      &__label {
        white-space: nowrap;
      }
    }

    &__session-select {
      position: relative;

      &__container {
        padding-right: var(--size-geo--nm);
        min-height: 6.125rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &__toggle, &__tooltip {
        background-color: var(--color-primary);
        border: 1px solid var(--color-text--inverse-dimmer);
        border-radius: 0.25rem;
      }

      &__toggle {
        display: inline-flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;

        &.show {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:hover {
          background-color: var(--color-bg--primary-hover);
        }

        > .app-analysis__session-athlete-label {
          font-size: var(--size-font--sm);
          padding: var(--size-geo--sm) var(--size-geo--nm) var(--size-geo--sm) calc(var(--size-geo--nm) - var(--size-geo--xs));

          &:not(:nth-last-child(2)) {
            margin-right: var(--size-geo--sm);
          }

          &:nth-last-child(2) {
            border-right: 1px solid var(--color-text--inverse-dimmer);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &__icon {
          padding: 0 var(--size-geo--sm);
          font-size: var(--size-font--nm);
          color: var(--color-text--inverse-dimmer);
        }

        &:hover &__icon {
          color: var(--color-text--inverse-dim);
        }
      }

      &__tooltip {
        border-top-left-radius: 0;

        &-container.__react_component_tooltip {
          position: absolute !important;
          background-color: var(--color-rooq--primary);
          border: 0 !important;
          padding: var(--size-geo--sm) 0;
          margin: 0 !important;
          box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.1);
        }

        &__grid {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-template-rows: auto;

          &__col {
            padding: var(--size-geo--sm) var(--size-geo--nm);

            &--header {
              font-size: var(--size-font--sm);
              color: var(--color-text--inverse-dim);
            }

            &--name[data-deletable="yes"]:hover {
              text-decoration: line-through;
              cursor: pointer;
            }

            &--name,
            &--score,
            &--duration,
            &--date,
            &--time {
              &, & * {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }


    &__filter {

      &__item {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        border-radius: 0.325rem;
        color: var(--color-text--default-dim);

        &__label,
        &__value,
        &__unset {
          padding: 0.625rem 0.75rem;
        }

        &__label,
        &__value {
          cursor: pointer;
          border: 1px solid var(--color-text--inverse-dimmest);
          background-color: #0A1F30;
        }

        &:hover:not(.app-analysis__filter__item--active) {

          .app-analysis__filter__item__label,
          .app-analysis__filter__item__value {
            border-color: var(--color-text--inverse-dimmer);
          }
        }

        &__label {
          border-top-left-radius: 0.325rem;
          border-bottom-left-radius: 0.325rem;
        }

        &__value {
          border-top-right-radius: 0.325rem;
          border-bottom-right-radius: 0.325rem;
          border-left-width: 0;
          font-weight: bold;
        }

        &__unset {
          cursor: pointer;
          background-color: #465b6b;
          color: #0A1F30;
          font-size: var(--size-font--sm);
          font-weight: bold;
          padding: 0.325rem 0.625rem;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;

          &:hover {

          }
        }
      }

      &__item--active {

        .app-analysis__filter__item__label,
        .app-analysis__filter__item__value {
          color: var(--color-text--inverse);
          border-color: #CA2243;
        }
      }
    }

    &__subscription-locked,
    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 15%;
      position: relative;
      z-index: 0;
      overflow: hidden;
      flex: 1;
      margin: var(--size-geo--md);

      .ui-button {
        z-index: 10;
      }

      .ui-text {
        text-align: center;
        max-width: 28rem;
        display: block;
        z-index: 10;
        text-shadow: 0 0 3.25rem hsla(207, 74%, 11%, 0.325);

        &-title {
          font-size: 1.875rem;
        }

        &:not(:last-child) {
          margin-bottom: 2.5rem;
        }
      }
    }

    &__empty {

      &:before {
        display: block;
        content: '';
        zoom: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        background: hsla(207, 72%, 11%, 1) url('./asset/brand/training-plan-mood-alt-sd.png') center/cover no-repeat;
        box-shadow: inset 0 0 15rem 15rem hsla(207, 74%, 11%, 0.325);
        z-index: 5;
        opacity: 0.65;
        border-radius: 0.375rem;
      }
    }
  }

  &-club {

    &__logo {
      display: block;
      margin: 0;
      padding: 0;
      border: 0;
      background-color: rgba(0, 0, 0, 0.1);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50%;
      overflow: hidden;

      &-picture {
        &__placeholder {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: rgba(20, 34, 45, 50%);
          border: 1px solid #2E3F4E;
          color: #dedede;
          border-radius: 50%;
          width: 5rem;
          height: 5rem;
          font-size: 5rem;
          cursor: pointer;

          &:hover {
            border: 1px solid #6d859b;

            > svg {
              color: var(--color-text--inverse-bright);
              opacity: 1;
            }
          }

          > svg {
            opacity: 0.75;
            color: var(--color-text--inverse);
            width: 1.5rem !important;
            height: 1.5rem !important;
          }
        }
      }
    }

    &__list {

      &-item {
      }
    }

    &__card {
      border-radius: 0.3125em;
      background-color: var(--color-bg--primary);
      padding: 1.3125em;

      &.app-banner-pane {
        max-height: 40vh;
      }

      .app-banner-pane__content {
        right: 0;
      }

      &__content {
        //padding-top: 25%;

        &__title {
          flex: 1;
          color: var(--color-text--inverse);

          .ui-text-title {
            margin-top: var(--size-geo--xs);
            font-size: calc(var(--size-geo--md) * 0.75);
          }

          .ui-fx--selectable {

            &:hover > .ui-text-title {
              text-decoration: underline;
            }
          }
        }

        &__actions {

          .ui-button {
            min-width: 14em;
          }
        }
      }

      &__grid {
        display: grid;
        grid-gap: var(--size-geo--nm);
        grid-template-columns: 1fr;
      }

      @include from-screen("desktop") {
        &__grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      @include from-screen("4k") {
        &__grid {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }

    &__cta-card {

    }

    &__details {

      > .app-club__card {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      > .ui-panel {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &-modal {
        min-width: 20rem;
        max-width: 80vw;
        min-height: 62.5vh;
        max-height: 90vh;
        position: relative;
        overflow: hidden;

        @include from-screen("tablet") {
          min-width: 22.5rem;
        }

        @include from-screen("desktop") {
          min-width: 25rem;
        }

        &__content {
          overflow: auto;
          background-color: var(--color-bg--primary-dark);
        }

        &__close.ui-button {
          padding: 0 !important;
          border-radius: 50% !important;
          position: absolute;
          top: 0.325em;
          right: 0.325em;
          width: 2.25em;
          height: 2.25em;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          > .ui-icon {
            margin: 0 0 0 0.1em !important;
            transform: scale(1.5);
          }
        }

        .app-banner-pane {
          min-height: 14rem;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .app-tab-navigation {
          overflow: initial;
        }

        .app-page-section-panel {
          border-radius: 0;
        }

        .app-banner-pane .ui-page-segment,
        .app-page-section-panel > .ui-page-segment {
          margin: var(--size-geo--nm);
        }

        .app-page-section-panel > .ui-page-segment + .ui-page-segment {
          margin-top: 0;
        }
      }
    }

    &__opening-hours {

      &__row {
        padding: var(--size-geo--xs) var(--size-geo--sm);
      }

      &--editable {

        .app-club__opening-hours__row {

          border-radius: 0.25em;
          cursor: pointer;

          &:hover {
            background-color: rgba(117, 19, 61, 0.5);
          }
        }
      }

    }

    &-search-modal {
      @include modal-single-col;
    }
  }

  &-training-plan {

    &__listing {
      display: flex;
      flex-direction: column;
      min-height: 0;
      overflow-y: auto;

      &--empty {
        align-items: center;
        justify-content: center;
        padding: 0 15%;
        position: relative;
        z-index: 0;
        overflow: hidden;

        &:before {
          display: block;
          content: '';
          zoom: 1;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          position: absolute;
          background: hsla(207, 72%, 11%, 1) url('./asset/brand/training-plan-mood-alt-sd.png') center/cover no-repeat;
          box-shadow: inset 0 0 15rem 15rem hsla(207, 74%, 11%, 0.325);
          z-index: 5;
          opacity: 0.65;
          border-radius: 0.375rem;
        }

        .ui-button {
          z-index: 10;
        }

        .ui-text {
          text-align: center;
          max-width: 28rem;
          display: block;
          z-index: 10;
          text-shadow: 0 0 3.25rem hsla(207, 74%, 11%, 0.325);

          &-title {
            font-size: 1.875rem;
          }

          &:not(:last-child) {
            margin-bottom: 2.5rem;
          }
        }
      }

      &__item {
        display: grid;
        grid-template-columns: 1fr min-content min-content;
        grid-column-gap: 2.5rem;
        border-radius: 0.3125rem;
        border: 1px solid hsla(207, 32%, 62%, 0.1);
        padding: 0;
        align-items: center;
        margin-bottom: 1rem;

        &__header {
          padding: 1.125rem;
          //cursor: pointer;
          //
          //&:hover {
          //  .ui-text-title {
          //    text-decoration: underline;
          //  }
          //}
        }

        &__breakdown {
          align-self: stretch;

          .app-analysis-training-session-breakdown {
            height: 100%;
          }
        }

        &__actions {
          padding: 0 1.25rem;

          .ui-button.ui-button--icon-only.ui-button--clr-default {
            color: var(--color-text--inverse-dim);
          }

          .ui-icon {
            transform-origin: center;
            transform: scale(1.75);
          }

          .ui-button.ui-button--badged {
            position: relative;

            &.ui-button--clr-default > .ui-icon {
              color: var(--color-text--inverse);
            }

            > .ui-text {
              position: absolute;
              top: 10%;
              right: 27.5%;
            }
          }
        }
      }
    }

    &__form {
      display: grid;
      overflow: hidden;
      grid-template-rows: 1fr;
      grid-template-columns: min-content 1fr;
      grid-column-gap: var(--size-geo--nm);

      &__master {
        margin-top: 0;
        margin-right: 0;
        max-height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      &__detail {
        margin-top: 0;
        margin-left: 0;
        border: 1px dashed #263544;
        border-radius: 0.325rem;
        transition: box-shadow 0.1s ease, border-color 0.1s ease;
        box-shadow: inset 0 0 0rem rgba(255, 255, 255, 0);
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: hidden;

        &.discipline-drag-over {
          //border-style: solid;
          border-color: var(--color-bg--accent);
          box-shadow: inset 0 0 2.5rem rgba(255, 255, 255, 0.05);
        }

        > .form-field {
          flex: 1;
          overflow: hidden;
          max-height: 100%;
        }
      }
    }

    &__disciplines-grid {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: var(--size-geo--nm);
      overflow: hidden;

      &__item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid #263544;
        border-radius: 0.325rem;
        cursor: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: transparent center/cover no-repeat;
        opacity: 0.75;
        transition: all 0.2s ease;

        &__container {
          border-radius: 0.325rem;
          position: relative;
          width: 8.75rem;
          padding-bottom: 95%;
          cursor: pointer;

          @include fx--pane-fade-in;

          &--disabled {
            cursor: default;
          }

          &:not(.app-training-plan__disciplines-grid__item__container--disabled) {

            > .app-training-plan__disciplines-grid__item:hover {
              opacity: 1;
            }
          }

          &.empty {
            overflow: hidden;
            background-color: hsla(212, 44%, 13%, 0.5);

            &:before {
              content: '';
              display: block;
              position: absolute;
              left: -8.75rem;
              top: 0;
              height: 100%;
              width: 8.75rem;
              background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.05) 50%, transparent 100%);
              animation: load-tile 1.125s cubic-bezier(0.4, 0.0, 0.2, 1);
              animation-iteration-count: infinite;
              animation-delay: inherit;
            }

            &:nth-child(1) {
              animation-delay: 0.1s;
            }

            &:nth-child(2) {
              animation-delay: 0.125s;
            }

            &:nth-child(3) {
              animation-delay: 0.15s;
            }

            &:nth-child(4) {
              animation-delay: 0.175s;
            }

            &:nth-child(5) {
              animation-delay: 0.2s;
            }
          }
        }
      }
    }

    &__unit {
      position: relative;
      padding: 0.625rem;
      border-radius: 0.325rem;

      @include fx--pane-fade-in;

      &__container {
        display: grid;
        grid-template-rows: min-content;
        grid-template-columns: minmax(3.5rem, auto) 1fr auto;
        border: 1px solid #263544;
        border-top-left-radius: 0.325rem;
        border-top-right-radius: 0.325rem;
        box-shadow: inset -1.25rem -2.5rem 5rem 1rem #121F2E;
        padding: 0.625rem;
        max-height: 100%;
        overflow: hidden;
        background: var(--color-bg--primary-darker-alt) top center/cover no-repeat;
        transition: all 0.2s ease;
        transform: scale(1);
        position: relative;
        z-index: 10;

        &:before {
          display: block;
          content: '';
          zoom: 1;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to bottom, hsla(212, 32%, 13%, .325) 0%, hsla(212, 44%, 13%, 1.0) 100%);
          z-index: 0;
        }

        &:hover {
          box-shadow: inset -1.25rem -2.5rem 5rem 0 #121F2E;
        }

        &.drop-highlight {
          border-color: var(--color-text--accent);
          transform: scale(0.9875);
          box-shadow: inset -1.25rem -2.5rem 3.75rem 0 #121F2E;
        }

        &.drag-highlight {
          transform: scale(0.9875);
          box-shadow: inset -1.25rem -2.5rem 6.25rem 0 #121F2E;
        }

        border-bottom-width: 0;
      }

      &__details {
        max-height: 100%;
        overflow: hidden;
        padding: 0 1rem 1rem 1rem;
        position: relative;
        z-index: 10;

        &__header {
          height: 3rem;
          margin-bottom: 0.5rem;
          font-weight: bold;

          > img {
            width: 2.25rem;
            height: 2.25rem;
            margin-right: 0.75rem;
          }
        }

        &__breakdown, &__goal__container {
          color: #94ADC1;
          font-size: var(--size-font--sm);

          > * > .ui-icon {
            margin-right: 0.325rem;
          }
        }

        &__breakdown {
          padding-bottom: 1rem;

          > *:not(:last-child) {
            margin-right: 1rem;
          }
        }

        &__goal {
          padding: 0.25rem 0.5rem;
          border-radius: 0.3125rem;
          background: rgba(255, 255, 255, 0.05);

          > .ui-icon {
            font-size: 1.25rem;
          }
        }
      }

      &__decor {
        padding: 0;
        position: relative;
        z-index: 10;

        &__step {
          font-family: var(--font-title);
          width: 3rem;
          height: 3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 2px solid #263544;
        }

        &__line {
          border-left: 2px dotted #2f4051;
          margin: 0.5em 0;
        }

        > .ui-icon:last-of-type {
          color: #2f4051;
          font-size: var(--size-font--md);
          transform: translate3d(0, -0.625rem, 0);
        }
      }

      &__handle {
        position: relative;
        z-index: 10;

        .ui-icon {
          font-size: var(--size-font--md);
          color: var(--color-text--inverse-dim);
        }

        &__ref {
          padding: var(--size-geo--nm);
          border-radius: 50%;
          cursor: grab;
        }
      }

      &__delete-icon {
        position: absolute;
        z-index: 10;
        top: 0.625rem;
        right: 0.625rem;
        border-bottom-left-radius: 0.325rem;
        border-top-right-radius: 0.325rem;
        background-color: #2D3F4D;
        cursor: pointer;
        padding: 0.125rem;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        opacity: 1;
        transition: all 0.2s ease;

        .dragging & {
          background-color: #23323e;
          opacity: 0.5;
        }

        &:hover {
          background-color: #3b4e60;

          > .ui-icon {
            transform: scale(1.125);
          }
        }

        > .ui-icon {
          transform-origin: center;
          transform: scale(1);
          transition: transform 0.15s ease;
        }
      }

      &__empty {

        .ui-text {
        }

        .ui-text-title {
          justify-content: center;
          font-size: var(--size-font--md);
          color: var(--color-text--default-dim);
          margin-bottom: 1rem;
        }

        .ui-text-block {
          text-align: center;
          font-size: var(--size-font--nm);
          color: var(--color-text--default-dimmer);
          margin: 0 3.5rem;
          line-height: 1.75 !important;
        }
      }

      &__cooldown {
        position: relative;
        z-index: 5;
        border: 1px solid hsla(210, 28%, 21%, 0.625);
        //border-top-style: dashed;
        border-bottom-left-radius: 0.325rem;
        border-bottom-right-radius: 0.325rem;
        padding: 0.825rem 0 0.625rem;
        font-size: var(--size-font--sm);
        color: var(--color-text--default-dimmer);
        transition: all 0.2s ease;
        transform: scale(1) translate3d(0, 0, 0);

        //:last-of-type > & {
        //  display: none;
        //}
        .app-training-plan__unit__container.drop-highlight ~ & {
          border-color: var(--color-bg--accent);
        }

        .app-training-plan__unit__container.drag-highlight ~ &,
        .app-training-plan__unit__container.drop-highlight ~ & {
          transform: scale(0.9875) translate3d(0, 0, 0);
        }
      }
    }

    &__unit-modal {
      max-height: 90vh;
      overflow: hidden;

      > form {
        min-height: 0;
        overflow: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      form > .app-page-section-panel {
        max-width: 28rem;
      }

      .form-field__message,
      .form-field__message .ui-text {
        white-space: normal;
        word-break: normal;
      }
    }

    &__banner-decor {
      background: var(--color-bg--primary-darker-alt) top center/cover no-repeat;
      padding-bottom: 25%;
      position: relative;

      > .ui-box {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include fx--pane-fade-in;
        animation-duration: 500ms;

        > img {
          width: 3.125rem;
          height: 3.125rem;
          opacity: 0.625;
        }

        > .ui-icon {
          display: block;
        }
      }
    }

    &__number-widget {

      &__container {
        padding: var(--size-geo--nm);
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-column-gap: var(--size-geo--md);
        align-items: flex-start;
      }

      &__decor {
        width: 2px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%);
      }


    }
  }

  &-spinner {

    &__pane {
      flex: 1;
      border-radius: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__list {

    &-item {
      padding: var(--size-geo--sm);
      @include fx--pane-fade-in;

      &__title {
        padding: 0 var(--size-geo--sm);
      }
    }
  }

  &-search {

    &-modal {
      overflow: hidden;
      min-height: 25vh;
      max-height: 90vh;

      @include from-screen("tablet") {
        min-height: 32.5vh;
        max-height: 80vh;
      }

      @include from-screen("laptop") {
        min-height: 50vh;
        max-height: 75vh;
      }
    }

    &-result {
      > .app__list-item {
        flex: 1;
      }

      .app__list-item__avatar {
        width: 50px;
        height: 50px;

        svg {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $rooq-primary--lighter;
      }
    }
  }
}

// </editor-fold>
