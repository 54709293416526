// <editor-fold "Roboto">

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-BlackItalic.eot');
  src: url('./asset/font/Roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-BlackItalic.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-LightItalic.eot');
  src: url('./asset/font/Roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-LightItalic.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-LightItalic.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-MediumItalic.eot');
  src: url('./asset/font/Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-MediumItalic.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-Black.eot');
  src: url('./asset/font/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-Black.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-Black.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-Bold.eot');
  src: url('./asset/font/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-Bold.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-Bold.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-ThinItalic.eot');
  src: url('./asset/font/Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-ThinItalic.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-Regular.eot');
  src: url('./asset/font/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-Regular.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-Regular.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-Light.eot');
  src: url('./asset/font/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-Light.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-Light.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-Thin.eot');
  src: url('./asset/font/Roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-Thin.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-Thin.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-BoldItalic.eot');
  src: url('./asset/font/Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-BoldItalic.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-Italic.eot');
  src: url('./asset/font/Roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-Italic.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-Italic.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./asset/font/Roboto/Roboto-Medium.eot');
  src: url('./asset/font/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Roboto/Roboto-Medium.woff2') format('woff2'),
  url('./asset/font/Roboto/Roboto-Medium.woff') format('woff'),
  url('./asset/font/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


// </editor-fold>


// <editor-fold "Eurosoft">

@font-face {
  font-family: 'Eurosoft';
  src: url('./asset/font/Eurosoft/Eurosoft-Semibold.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-Semibold.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-Semibold.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-Semibold.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Eurosoft';
  src: url('./asset/font/Eurosoft/Eurosoft-Regular.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-Regular.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-Regular.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-Regular.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Eurosoft';
  src: url('./asset/font/Eurosoft/Eurosoft-Light.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-Light.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-Light.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-Light.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Eurosoft';
  src: url('./asset/font/Eurosoft/Eurosoft-MediumItalic.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-MediumItalic.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-MediumItalic.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Eurosoft-RegularItalic';
  src: url('./asset/font/Eurosoft/Eurosoft-RegularItalic.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-RegularItalic.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-RegularItalic.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Eurosoft';
  src: url('./asset/font/Eurosoft/Eurosoft-Bold.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-Bold.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-Bold.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-Bold.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Eurosoft';
  src: url('./asset/font/Eurosoft/Eurosoft-Medium.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-Medium.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-Medium.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-Medium.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Eurosoft';
  src: url('./asset/font/Eurosoft/Eurosoft-BoldItalic.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-BoldItalic.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-BoldItalic.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Eurosoft';
  src: url('./asset/font/Eurosoft/Eurosoft-LightItalic.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-LightItalic.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-LightItalic.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Eurosoft';
  src: url('./asset/font/Eurosoft/Eurosoft-SemiboldItalic.eot');
  src: url('./asset/font/Eurosoft/Eurosoft-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
  url('./asset/font/Eurosoft/Eurosoft-SemiboldItalic.woff2') format('woff2'),
  url('./asset/font/Eurosoft/Eurosoft-SemiboldItalic.woff') format('woff'),
  url('./asset/font/Eurosoft/Eurosoft-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}


// </editor-fold>
