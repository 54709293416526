@import "~@bitsolve/react-common/dist/styles/utility";
@import "~@bitsolve/react-common/dist/styles/reset";
@import "~@bitsolve/react-common/dist/styles/ui";
@import "~@bitsolve/react-common/dist/styles/modals";
@import "~@bitsolve/react-common/dist/styles/forms";
@import "~@bitsolve/react-common/dist/styles/loading-spinner/all";
@import "./ui/date-picker-control";
@import "./ui/select-control";

// fix safari contenteditable issue (https://stackoverflow.com/questions/20435166/contenteditable-not-working-in-safari-but-works-in-chrome)
[contenteditable], input, textarea {
  -webkit-user-select: text;
  user-select: text;
}

:root {
  // sizing
  --size-base: 13px;

  @include from-screen('mobile-l') {
    --size-base: 14px;
  }

  @include from-screen('tablet') {
    --size-base: 15px;
  }

  @include from-screen('laptop') {
    --size-base: 16px;
  }

  @include from-screen('desktop') {
    --size-base: 17px;
  }

  @include from-screen('4k') {
    --size-base: 18px;
  }
}
