.welcome {

  &-background-container {
    display: flex;
    width: 100vw;
    height: 100vh;

    flex-direction: row;

    background-color: white;
  }

  &-left-side {
    &-container {
      position: relative;

      display: flex;
      overflow: hidden;
      width: 50vw;
      height: 100vh;

      justify-content: center;
      align-items: center;

      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: rgb(10, 30, 50);

      .slick-slider,
      .slick-list {
        width: 100%;
        height: 100%;
      }

      .slick-dots {
        position: absolute;
        bottom: 17.5%;

        width: 100%;

        li {
          margin: 0 2px;

          button {
            position: relative;
            padding: 0;

            &::before {
              position: absolute;
              top: 50%;
              left: 50%;

              display: block;
              width: 8px;
              height: 8px;

              opacity: 0.1;
              border-radius: 50%;
              background: white;

              transform: translate(-50%, -50%);

              content: '';
            }
          }

          &.slick-active button::before {
            opacity: 1;
          }
        }
      }
    }

    &-logo,
    &-subline {
      position: absolute;
      left: 50%;
      z-index: 1;

      transform: translate(-50%, 0);
    }

    &-logo {
      top: 50px;
      width: 142px;
      height: 51px;
    }

    &-subline {
      top: 113px;
      width: 88px;
      height: 8px;
    }

    &-slide {
      height: 100vh;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &-content {
      position: absolute;
      bottom: calc(17.5% + 70px);
      left: 0;

      padding: 0 25%;
      width: 100%;

      text-align: center;

      &-headline {
        justify-content: center;

        font-size: var(--size-font--lg);
      }

      &-text {
        margin-top: 20px;
      }
    }
  }

  &-right-side {
    &-container {
      display: flex;
      width: 50vw;
      height: 100vh;

      flex-direction: column;
      justify-content: center;
      align-items: center;

      background-color: transparent;

      color: black;

      .ui-text-title {
        &, span {
          line-height: 1.1 !important;
        }
      }

      .ui-text-block {
        line-height: 1.625 !important;
      }

      .ui-button {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        &--clr-primary {
          background-color: var(--color-rooq--primary);
        }

        &--ghost {
          font-weight: bold;
          text-transform: none;

          &.ui-button--clr-default:hover {
            color: var(--color-text--inverse-dim) !important;
          }
        }
      }

      .form-field__control--password,
      .form-field__control--text {
        color: var(--color-text);
        font-weight: bold;
      }
    }

    &-initial-logo {
      width: 45px;
      height: 45px;

      transform: translate(-13%, 0);
    }

    &-h1,
    &-p {
      margin-bottom: 60px;

      white-space: pre-line;
    }

    &-h1 {
      width: 70%;

      font-size: 55px;
      line-height: 60px !important;
      font-weight: 400;
      text-align: center;
      letter-spacing: 0.075em;
    }

    &-p {
      width: 50%;

      font-size: 16px;
      line-height: 28px !important;
      font-weight: 300;
      letter-spacing: 0.075em;
    }

    &-buttons {
      height: 100%;

      align-items: stretch;

      .ui-button {
        align-items: center;
      }
    }

    &-confirm-email {
      &-headline,
      &-text {
        text-align: center;
      }

      &-headline {
        &, & span {
          line-height: 1.1 !important;
        }
      }
    }
  }
}
