@keyframes text-fade-in {
  0% {
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(-1080deg);
  }
}


@keyframes load-tile {
  from {
    left: -8.75rem;
  }
  to {
    left: 100%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes pane-fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes pane-fade-out {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes pane-fade-left {
  0% {
    transform: translateZ(0) translateX(-10%) scale(0.975);
    opacity: 0;
  }

  to {
    transform: translateZ(0) translateX(0) scale(1);
    opacity: 1;
  }
}


@keyframes pane-fade-right {
  0% {
    transform: translateZ(0) translateX(10%) scale(0.975);
    opacity: 0;
  }

  to {
    transform: translateZ(0) translateX(0) scale(1);
    opacity: 1;
  }
}

@keyframes pane-fade-down {
  0% {
    transform: translateZ(0) translateY(-10%);
    opacity: 0.25;
  }

  to {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}


@keyframes pane-fade-up {
  0% {
    transform: translateZ(0) translateY(10%);
    opacity: 0.25;
  }

  to {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

$fx-basedur: 0.4875s;

.ui-fx {
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-duration: $fx-basedur;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  will-change: transform;

  &--xslo {
    animation-duration: $fx-basedur * 2.5;
  }

  &--slo {
    animation-duration: $fx-basedur * 1.5;
  }

  &--norm {
    animation-duration: $fx-basedur;
  }

  &--fast {
    animation-duration: $fx-basedur * 0.5;
  }

  &--xfast {
    animation-duration: $fx-basedur * 0.25;
  }

  &__pane-fade-in {
    animation-name: pane-fade-in;
  }

  &__pane-fade-up {
    animation-name: pane-fade-up;
  }

  &__pane-fade-right {
    animation-name: pane-fade-right;
  }

  &__pane-fade-down {
    animation-name: pane-fade-down;
  }

  &__pane-fade-left {
    animation-name: pane-fade-left;
  }

  &--selectable {
    cursor: pointer;

    &-bg {
      &:hover {
        background-color: var(--color-bg--control);
      }
    }
  }
}

@mixin fx--spin() {
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: $fx-basedur * 3;
  animation-name: spin;
}

@mixin fx--pane-fade() {
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-duration: 0.375s;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

@mixin fx--pane-fade-in() {
  @include fx--pane-fade;
  animation-name: pane-fade-in;
}


@mixin fx--pane-fade-out() {
  @include fx--pane-fade;
  animation-name: pane-fade-out;
}

@mixin fx--pane-fade-up() {
  @include fx--pane-fade;
  animation-name: pane-fade-up;
}

@mixin fx--pane-fade-down() {
  @include fx--pane-fade;
  animation-name: pane-fade-down;
}

@mixin fx--pane-fade-left() {
  @include fx--pane-fade;
  animation-name: pane-fade-left;
}

@mixin fx--pane-fade-right() {
  @include fx--pane-fade;
  animation-name: pane-fade-right;
}
