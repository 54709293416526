$ui-loading-spinner-color--inverse: #ddd;
$ui-loading-spinner-color: #222;

$dark-ish: #1a1b1c;
$light-ish: #fafbfc;

$rooq-primary: #0A1F30;
$rooq-primary--dark: darken(desaturate($rooq-primary, 5%), 5%);
$rooq-primary--lighter: #263544;
$rooq-primary--lightest: #313F4D;
$rooq-accent: #CA2243;
$rooq-accent--dark: #ab183b;
$rooq-accent--bright: #cd1540;
$rooq-accent--neon: #f81139;
$rooq-accent--text: $rooq-accent;

// session color palette
$rooq-session--1-r: 160;
$rooq-session--1-g: 247;
$rooq-session--1-b: 122;
$rooq-session--1: rgb($rooq-session--1-r, $rooq-session--1-g, $rooq-session--1-b);

$rooq-session--2-r: 106;
$rooq-session--2-g: 236;
$rooq-session--2-b: 227;
$rooq-session--2: rgb($rooq-session--2-r, $rooq-session--2-g, $rooq-session--2-b);

$rooq-session--3-r: 255;
$rooq-session--3-g: 239;
$rooq-session--3-b: 90;
$rooq-session--3: rgb($rooq-session--3-r, $rooq-session--3-g, $rooq-session--3-b);

//$rooq-session--4: #f2556f;
//$rooq-session--5: #8381fc;

// chart color palette
$rooq-chart--1: #1d4c7c;
$rooq-chart--2: #BA3547;
$rooq-chart--3: #4b95e3;
$rooq-chart--4: #8c1c4c;
$rooq-chart--5: #629d45;
$rooq-chart--6: #d6a51a;

$ui-geo-sizes: (
    "xs": var(--size-geo--xs),
    "sm": var(--size-geo--sm),
    "nm": var(--size-geo--nm),
    "md": var(--size-geo--md),
    "lg": var(--size-geo--lg),
    "xl": var(--size-geo--xl),
    "n": 0
);

$ui-text-colors: (
    "def": var(--color-text--default),
    "defb": var(--color-text--default-bright),
    "defd": var(--color-text--default-dim),
    "inv": var(--color-text--inverse),
    "invb": var(--color-text--inverse-bright),
    "invd": var(--color-text--inverse-dim),
    "pri": var(--color-text--primary),
    "acc": var(--color-text--accent),
    "suc": var(--color-text--success),
    "inf": var(--color-text--info),
    "wrn": var(--color-text--warn),
    "err": var(--color-text--error)
);


:root {
  // font stuff:
  --font-sans-serif: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial Neue', 'Arial', -apple-system, 'BlinkMacSystemFont', sans-serif;
  --font-sans-serif-2: 'Eurosoft', 'Helvetica Neue', 'Helvetica', 'Arial Neue', 'Arial', -apple-system, 'BlinkMacSystemFont', sans-serif;
  --font-serif: 'Brawler', 'Times New Roman', 'Times', serif;
  --font-monospace: 'Iosevka', 'Operator Mono', 'Roboto Mono', 'Fira Code', 'Consolas', 'Menlo', 'Monaco', monospace;
  --font-default: var(--font-sans-serif);
  --font-title: var(--font-sans-serif-2);

  // sizes:
  // - fonts
  --size-font--xl: 3.5rem;
  --size-font--lg: 2.125rem;
  --size-font--md: 1.25rem;
  --size-font--nm: 1rem;
  --size-font--sm: 0.8625rem;
  --size-font--xs: 0.75rem;
  // - geometry
  --size-geo--xl: 7.5rem;
  --size-geo--lg: 5rem;
  --size-geo--md: 2.5rem;
  --size-geo--nm: 1.5rem;
  --size-geo--sm: 0.625rem;
  --size-geo--xs: 0.325rem;
  // - (border-)radius
  --size-radius--xs: 0.125rem;
  --size-radius--sm: 0.25rem;
  --size-radius--nm: 0.3125rem;
  --size-radius--md: 0.5rem;
  --size-radius--lg: 0.75rem;
  --size-radius--xl: 1rem;
  --size-radius--round: 50%;

  // colors:

  --color-dark: #{$dark-ish};
  --color-light: #{$light-ish};

  --color-rooq--primary: #{$rooq-primary};
  --color-rooq--primary-text: #{$rooq-primary--dark};
  --color-rooq--primary-lighter: #{$rooq-primary--lighter};
  --color-rooq--primary-lightest: #{$rooq-primary--lightest};
  --color-rooq--help: #4A9DF4;
  --color-rooq--help-bg: hsl(207, 66%, 11%);
  --color-rooq--help-bg--hover: hsl(207, 60%, 13%);
  --color-rooq-border--outer: #606F7C;
  --color-rooq-border--inner: #0F1F2F;

  --color-text--default: #{$dark-ish};
  --color-text--inverse: #{$light-ish};
  --color-text--primary: #{$rooq-primary--dark};
  --color-text--accent: #{$rooq-accent--text};
  --color-text--success: rgb(37, 162, 32);
  --color-text--info: rgb(25, 118, 126);
  --color-text--warn: rgb(231, 127, 34);
  --color-text--error: rgb(191, 30, 81);

  --color-text--default-hover: #{darken(saturate($dark-ish, 2.5%), 5%)};
  --color-text--inverse-hover: #{lighten(desaturate($light-ish, 2.5%), 5%)};
  --color-text--primary-hover: #{lighten($rooq-primary--dark, 5%)};
  --color-text--accent-hover: #{lighten($rooq-accent--text, 5%)};
  --color-text--success-hover: rgb(37, 162, 32);
  --color-text--info-hover: rgb(7, 155, 196);
  --color-text--warn-hover: rgba(255, 162, 80, 1);
  --color-text--error-hover: rgb(226 56 89);

  --color-text--default-bright: #{darken(saturate($dark-ish, 2.5%), 7.5%)};
  --color-text--inverse-bright: #{lighten(desaturate($light-ish, 2.5%), 7.5%)};
  --color-text--default-dim: #94adc1;
  --color-text--default-dimmer: #6e8496;
  --color-text--inverse-dim: #94adc1;
  --color-text--inverse-dimmer: #53758f;
  --color-text--inverse-dimmest: #223545;

  --color-bg--default: #efefef;
  --color-bg--primary-dark: #1a2a39;
  --color-bg--primary-darker: hsl(205, 70%, 11%);
  --color-bg--primary-darker-alt: hsla(212, 44%, 13%, 1.0);
  --color-bg--primary: #27394a;
  --color-bg--accent: #{$rooq-accent};
  --color-bg--success: rgb(46, 162, 42);
  --color-bg--info: rgb(12, 121, 153);
  --color-bg--warn: rgba(255, 162, 80, 1);
  --color-bg--error: rgba(255, 0, 72, 1);

  --color-bg--default-hover: #{lighten(#efefef, 5%)};
  --color-bg--primary-hover: #{lighten(#1A2A39, 5%)};
  --color-bg--accent-hover: #{lighten($rooq-accent, 5%)};
  --color-bg--success-hover: #{lighten(rgb(46,162,43), 5%)};
  --color-bg--info-hover: #{lighten(rgb(12, 121, 153), 5%)};
  --color-bg--warn-hover: #{lighten(rgba(255, 162, 80, 1), 5%)};
  --color-bg--error-hover: #{lighten(rgba(255, 0, 72, 1), 5%)};

  --color-bg--default-dark: #{darken(#efefef, 5%)};
  --color-bg--accent-dark: #{darken(hsl(270, 67%, 51%), 5%)};
  --color-bg--success-dark: #{darken(rgb(46,162,43), 5%)};
  --color-bg--info-dark: #{darken(rgb(12, 121, 153), 5%)};
  --color-bg--warn-dark: #{darken(rgba(255, 162, 80, 1), 5%)};
  --color-bg--error-dark: #{darken(rgba(255, 0, 72, 1), 5%)};

}

@mixin bg-bar-gradient($r, $g, $b) {
  background: transparent linear-gradient(to right, rgba($r, $g, $b, 0.4125) 0%, rgba($r, $g, $b, 0.625) 50%, saturate(lighten(rgba($r, $g, $b, 1), 2.5%), 5%) 85%, rgba($r, $g, $b, 0.825) 100%);
}
