.react-calendar {
  min-width: 20rem;
  max-width: 100%;
  background: var(--color-bg--primary);
  border: 0;
  border-radius: 0.325em;
  font-family: inherit;
  line-height: 1.25em;
  margin-top: -0.175em;
  box-shadow: 0 0 0.75em rgba(0, 0, 0, 0.1);
}

.react-calendar--doubleView {
  min-width: 40rem;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

  &, & > abbr {
    transition: opacity 0.2s ease, color 0.2s ease, background 0.2s ease;
  }
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 2.75rem;
  margin-bottom: 0.5em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  > *:first-child {
    border-top-left-radius: 0.325em;
  }

  > *:last-child {
    border-top-right-radius: 0.325em;
  }
}

.react-calendar__navigation button {
  border: 0;
  min-width: 2.75rem;
  background: none;
  opacity: 0.9;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: rgba(255, 255, 255, 0.075);
  opacity: 1;
}

.react-calendar__navigation button[disabled] {
  opacity: 0.625;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  margin-bottom: 0.25em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;

  > abbr[title] {
    cursor: default;
    text-decoration: none;
    border: 0;
  }
}


.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  //font-size: 0.75em;
  //padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: var(--color-text--default-dim);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(224, 55, 74, 0.62);
  font-size: var(--size-font--sm);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  //padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0;
  background: none;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > abbr {
    border-radius: 0;
    margin: 0 !important;
    line-height: 1;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.react-calendar__decade-view__years__year {
    color: var(--color-text--default-dim);
    font-weight: bold;
  }

  &--now {

    &.react-calendar__decade-view__years__year {
      color: var(--color-text--inverse-bright);
    }
  }

  &.react-calendar__month-view__days__day {
    > abbr {
      height: 2.125rem;
      width: 2.125rem;
      border-radius: 50%;
    }
  }

  &.react-calendar__year-view__months__month {
    > abbr {
      padding: 0.5em;
      border-radius: 0.25em;
    }
  }
}

.react-calendar__tile:disabled {
  opacity: 0.25;
}

.react-calendar__tile:enabled:hover > abbr,
.react-calendar__tile:enabled:focus > abbr {
  background-color: rgba(255, 255, 255, 0.075);
}

.react-calendar__tile--now > abbr {
  background-color: rgba(255, 255, 255, 0.05);
  font-weight: bold;
}

.react-calendar__tile--now:enabled:hover > abbr,
.react-calendar__tile--now:enabled:focus > abbr {
  background-color: rgba(255, 255, 255, 0.1);
}

.react-calendar__tile--hasActive > abbr {
  //background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover > abbr,
.react-calendar__tile--hasActive:enabled:focus > abbr {
  //background: #a9d4ff;
}

.react-calendar__tile--active > abbr {
  background: var(--color-bg--accent);
  color: #fff;
  font-weight: bold;
}

.react-calendar__tile--active:enabled:hover > abbr,
.react-calendar__tile--active:enabled:focus > abbr {
  background: var(--color-bg--accent-hover);
}

.react-calendar--selectRange .react-calendar__tile--hover > abbr {
  background-color: #e6e6e6;
}
